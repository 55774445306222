/* eslint-disable @typescript-eslint/no-explicit-any */
import { ifElse, is, pipe, reject, isNil, isEmpty, identity } from 'ramda'

export const reduceTree =
  <T, V>(
    reducer: (
      prev: V,
      next: Record<string, any>,
      index: number,
      path: string[],
      parent: Record<string, any>,
      coordinate: [number, number], // [depth, levelIndex]
      array: any[]
    ) => any,
    initialState: V,
    includeObjectCalls?: boolean
  ) =>
  (tree: T): any => {
    if (!tree) return initialState

    let index = -1

    const reduceLevel = (
      object: ArrayLike<T> | { [s: string]: T },
      initial: V,
      path: string[],
      depth: number
    ): V =>
      Object.entries(object).reduce((prev, [key, value], levelIndex, array) => {
        const newPath = [...path, key]
        if (typeof value === 'object') {
          if (includeObjectCalls) {
            return reduceLevel(
              value as ArrayLike<T> | { [s: string]: T },
              reducer(
                prev,
                { key, value },
                index,
                newPath,
                object,
                [depth, levelIndex],
                array
              ),
              newPath,
              depth + 1
            )
          }
          return reduceLevel(
            value as ArrayLike<T> | { [s: string]: T },
            prev,
            newPath,
            depth + 1
          )
        }

        index++

        return reducer(
          prev,
          { key, value },
          index,
          newPath,
          object,
          [depth, levelIndex],
          array
        )
      }, initial)

    return reduceLevel(tree, initialState, [], 0)
  }

export const removeEmptyNodes = ifElse(
  is(Object),
  pipe(reject<any>(isNil), reject<any>(isEmpty)),
  identity
)

export const countTreeNodes = reduceTree(prev => prev + 1, 0)
export const getFirstTreeValue = reduceTree(
  (prev, next, index) => (index === 0 ? next.value : prev),
  null
)

export const flattenTree = reduceTree(
  (prev, { key, value }) => ({ ...prev, [key]: value }),
  {}
)
