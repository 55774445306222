import * as React from 'react'
import * as css from './RelatedContent.module.scss'
import classnames from 'classnames'
import {
  TextBody,
  TitleSmall,
  TextDescription,
  TitleLarge,
  TypographyTag,
} from '../Typography'
import { Link, LinkType } from '../Link'

export interface RelatedContentProps {
  className?: string
  imageComponent?: React.ReactNode
  icon?: React.ReactNode
  url: string
  multiContent?: boolean
  title: string
  preamble?: string
  linkText: string
  style?: React.CSSProperties
  largeViewType?: boolean
  centerText?: boolean
}

export const RelatedContent: React.FC<RelatedContentProps> = ({
  className,
  imageComponent,
  largeViewType = false,
  icon,
  url,
  multiContent,
  title,
  preamble,
  linkText,
  centerText,
  style,
}) => {
  const classes = classnames(css.relatedContent, className, {
    [css.relatedContentSingle]: !multiContent,
    [css.relatedContentMulti]: multiContent,
    [css.relatedContentCenter]: centerText,
  })

  return (
    <div data-testid="RelatedContent" className={classes} style={style}>
      {imageComponent ? (
        <div className={css.imageContainer}>{imageComponent}</div>
      ) : (
        <div className={css.iconContainer}>{icon}</div>
      )}
      <div className={css.textContainer}>
        {largeViewType ? (
          <>
            <TitleLarge tag={TypographyTag.H2} className={css.title}>
              {title}
            </TitleLarge>
            {preamble && (
              <TextBody className={classnames(css.preamble, css.preambleFour)}>
                {preamble}
              </TextBody>
            )}
            <div className={css.linkContainer}>
              <Link type={LinkType.Action} href={url}>
                {linkText}
              </Link>
            </div>
          </>
        ) : (
          <div className={css.textContainer}>
            <TitleSmall>{title}</TitleSmall>
            {preamble && (
              <TextDescription className={css.preamble}>
                {preamble}
              </TextDescription>
            )}
            <div className={css.linkContainer}>
              <Link href={url}>{linkText}</Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
