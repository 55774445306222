// extracted by mini-css-extract-plugin
export var abbrevation = "richTextRender-module--abbrevation--0d3ef";
export var abbrevationButton = "richTextRender-module--abbrevationButton--39975";
export var downloadContainer = "richTextRender-module--downloadContainer--776de";
export var embeddedVideoContainer = "richTextRender-module--embeddedVideoContainer--c9f1d";
export var faqBlockContainer = "richTextRender-module--faqBlockContainer--40f78";
export var inlinePopover = "richTextRender-module--inlinePopover--99bb2";
export var inlineWidget = "richTextRender-module--inlineWidget--57be8";
export var inlineWidgetError = "richTextRender-module--inlineWidgetError--71ed6";
export var linkCollection = "richTextRender-module--linkCollection--f9598";
export var linkImageBlockContainer = "richTextRender-module--linkImageBlockContainer--72636";
export var styledImageContainer = "richTextRender-module--styledImageContainer--f3f1a";
export var videoFullWidth = "richTextRender-module--videoFullWidth--21e62";
export var videoHideDescription = "richTextRender-module--videoHideDescription--b4116";