import classnames from 'classnames'
import React, {
  ButtonHTMLAttributes,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  CSSProperties,
  forwardRef,
  Children,
} from 'react'

import * as css from './Button.module.scss'

export interface BaseButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void
  children?: ReactNode
  /**
   * Show button styled as an link
   */
  loading?: boolean
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
  className?: string
  fullWidth?: boolean
  style?: CSSProperties
  circled?: boolean
  icon?: ReactNode
  hoverIcon?: ReactNode
}

interface OptionalAria extends BaseButtonProps {
  asType?: Exclude<ButtonType, ButtonType.Icon>
  'aria-label'?: string
}

interface WithAria extends BaseButtonProps {
  asType: ButtonType.Icon
  'aria-label': string
}

export type ButtonProps = OptionalAria | WithAria

export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Danger = 'Danger',
  Tertiary = 'Tertiary',
  Link = 'Link',
  InlineLink = 'InlineLink',
  Icon = 'Icon',
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      onClick,
      children = null,
      loading,
      asType = ButtonType.Primary,
      disabled,
      className,
      fullWidth,
      circled = false,
      size = 'medium',
      style,
      type = 'button',
      hoverIcon = '',
      icon = '',
      ...rest
    } = props

    const asLinkType =
      asType === ButtonType.Link || asType === ButtonType.InlineLink

    return (
      <button
        ref={ref}
        onClick={e => (!disabled && onClick ? onClick(e) : null)}
        style={style}
        className={classnames(
          {
            'e-btn': !asLinkType,
            [css.buttonLink]: asLinkType,
            'e-btn--icon': asType === ButtonType.Icon,
            [css.iconButton]: icon && hoverIcon,
            'e-btn--circled': circled,
            'e-btn--secondary': asType === ButtonType.Secondary,
            'e-btn--danger': asType === ButtonType.Danger,
            'e-btn--tertiary': asType === ButtonType.Tertiary,
            'e-btn--loading': loading,
            [css.fullWidth]: fullWidth,
            'e-btn--sm': size === 'small',
            'e-btn--md': size === 'medium',
            'e-btn--lg': size === 'large',
          },
          className
        )}
        data-testid="button"
        disabled={disabled}
        type={type}
        {...rest}
      >
        {loading ? (
          <>
            <span></span>
            <span></span>
            <span></span>
          </>
        ) : (
          Children.map(children, child =>
            typeof child === 'string' ? (
              <span
                className={classnames({
                  'e-link': asLinkType,
                  'e-link--inline': asType === ButtonType.InlineLink,
                })}
              >
                {child}
              </span>
            ) : (
              child
            )
          )
        )}
        {icon && icon}
        {hoverIcon && hoverIcon}
      </button>
    )
  }
)

Button.displayName = 'Button'
