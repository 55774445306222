import React, { FC, ReactNode } from 'react'

import {
  ContentLoader as ContentLoaderComponent,
  ContentLoaderBackgroundColor,
  ContentLoaderProps,
} from '../ContentLoader/ContentLoader'

import { EmptyState, ErrorState } from '.'

export interface ContentLoaderWithStatesProps extends ContentLoaderProps {
  addWhiteBackground?: boolean
  backgroundColor?: ContentLoaderBackgroundColor
  centered?: boolean
  emptyInvertedIcon?: boolean
  emptyRichTerm?: ReactNode
  emptyTerm?: string
  emptyTitle?: string
  errorTitle?: ReactNode | string
  errorTerm?: ReactNode | string
  invertedIcon?: boolean
  noPadding?: boolean
}

export const ContentLoaderWithStates: FC<ContentLoaderWithStatesProps> = ({
  addWhiteBackground,
  backgroundColor = 'grey',
  centered,
  emptyInvertedIcon,
  emptyRichTerm,
  emptyTitle,
  emptyTerm,
  errorTitle,
  errorTerm,
  invertedIcon,
  noPadding,
  renderError,
  renderEmpty,
  ...rest
}) => (
  <ContentLoaderComponent
    renderEmpty={
      renderEmpty ||
      ((emptyTerm || emptyRichTerm) && (
        <EmptyState
          addWhiteBackground={addWhiteBackground}
          centered={centered}
          invertedIcon={emptyInvertedIcon}
          emptyRichTerm={emptyRichTerm}
          emptyTitle={emptyTitle}
          emptyTerm={emptyTerm}
          noPadding={noPadding}
        />
      ))
    }
    renderError={
      renderError || (
        <ErrorState
          addWhiteBackground={addWhiteBackground}
          centered={centered}
          invertedIcon={invertedIcon}
          errorTitle={errorTitle}
          errorTerm={errorTerm}
        />
      )
    }
    backgroundColor={backgroundColor}
    {...rest}
  />
)
