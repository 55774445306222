import classnames from 'classnames'
import React, { FC, StyleHTMLAttributes } from 'react'
import { useRef } from 'react'

import { TextCaps } from '../Typography'
import * as css from './Placeholder.module.scss'

export interface PlaceholderProps {
  title: string
  /**
   * Will hide dimensions panel
   */
  showDetails?: boolean
  /*
   * Will override default height in pixels.
   * @default 100%
   */
  style?: StyleHTMLAttributes<HTMLDivElement>
  className?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

export const Placeholder: FC<PlaceholderProps> = ({
  title,
  showDetails = false,
  className,
  style,
  data,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  return process.env.CONTEXT !== 'production' ? (
    <div
      ref={ref}
      data-testid="Placeholder"
      className={classnames(css.placeholder, className)}
      style={style}
    >
      <div className={css.content}>
        <TextCaps className={css.textCaps}>{title}</TextCaps>
        {data && showDetails && (
          <pre className={css.data}>
            <code>{JSON.stringify(data, null, 2)}</code>
          </pre>
        )}
      </div>
    </div>
  ) : null
}
