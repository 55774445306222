import classNames from 'classnames'
import React, { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react'
import * as css from './DataList.module.scss'

interface DataListProps extends ButtonHTMLAttributes<HTMLDListElement> {
  hasZebra?: boolean
  hasExtraPadding?: boolean
}

export const DataList: FC<PropsWithChildren<DataListProps>> = ({
  hasZebra,
  hasExtraPadding,
  className,
  children,
}) => {
  return (
    <dl
      className={classNames(
        css.dataList,
        {
          [css.dataListZebra]: hasZebra,
          [css.dataListMorePadding]: hasExtraPadding,
        },
        className
      )}
    >
      {children}
    </dl>
  )
}
