import React, { forwardRef, KeyboardEvent } from 'react'

import { Button, ButtonType } from '../Button'
import { ElvisIcon } from '../ElvisIcon'
import { TextMedium } from '../Typography'
import { MediaTypes, useMedia } from '../../hooks/useMedia'
import { colors } from '../../theme'

import * as css from './MenuButton.module.scss'

export interface MenuButtonProps {
  onClick: () => void
  isOpen: boolean
  onKeyDown?: (e: KeyboardEvent) => void
}

export const MenuButton = forwardRef<HTMLButtonElement, MenuButtonProps>(
  ({ onClick, isOpen, onKeyDown }, ref) => {
    const desktop = useMedia(MediaTypes.DESKTOP)

    return (
      <Button
        ref={ref}
        aria-label={isOpen ? 'Lukk meny' : 'Åpne meny'}
        asType={desktop ? ButtonType.Primary : ButtonType.Icon}
        onClick={onClick}
        onKeyDown={onKeyDown}
        icon={isOpen && <ElvisIcon iconName="removeCircleColor" />}
        hoverIcon={isOpen && <ElvisIcon iconName="removeCircleFilledColor" />}
      >
        {isOpen ? (
          <>
            <TextMedium className={css.menuToggleText}>
              <b>Lukk</b>
            </TextMedium>
          </>
        ) : (
          <ElvisIcon iconName="menuBold" color={colors.white} />
        )}
      </Button>
    )
  }
)

MenuButton.displayName = 'MenuToggleButton'
