import * as React from 'react'
import * as css from './LinkBlock.module.scss'
import classnames from 'classnames'
import { Link } from '../Link'
import { LinkType } from '../Link/Link'
import { TitleSmall } from '../Typography'
import { ElvisIcon, IconName } from '../ElvisIcon'

export interface LinkBlockProps {
  icon?: React.ReactNode
  children?: React.ReactNode
  title: string
  className?: string
  style?: React.CSSProperties
  href?: string
  target?: '_self' | '_blank' | undefined
  rel?: string
}

export const LinkBlock: React.FC<LinkBlockProps> = ({
  icon,
  children,
  title,
  className,
  style,
  href = '',
  target,
  rel,
}) => {
  return (
    <Link
      data-testid="LinkBlock"
      style={style}
      className={classnames(css.linkBlock, className, {
        [css.withBody]: !!children,
        [css.withIcon]: !!icon,
      })}
      href={href}
      type={LinkType.NoDecoration}
      target={target}
      rel={rel}
    >
      <div className={css.content}>
        {icon && (
          <div className={css.wrapperIcon}>
            {<ElvisIcon iconName={icon as IconName} />}
          </div>
        )}
        <TitleSmall className={css.header}>{title}</TitleSmall>
        <div className={css.body}>{children}</div>
      </div>
      <div className={css.wrapperArrow}>
        <ElvisIcon iconName="arrowLongRightBold" fontSize={24} />
      </div>
    </Link>
  )
}
