// extracted by mini-css-extract-plugin
export var closeButton = "Header-module--closeButton--41f5e";
export var header = "Header-module--header--29bb7";
export var headerActive = "Header-module--headerActive--eb70b";
export var headerBackground = "Header-module--header-background--98138";
export var headerMenu = "Header-module--headerMenu--730a0";
export var headerMenuActive = "Header-module--headerMenuActive--090e8";
export var headerMenuCloseButton = "Header-module--headerMenuCloseButton--7f1da";
export var headerMenuContent = "Header-module--headerMenuContent--88c67";
export var headerMenuTop = "Header-module--headerMenuTop--1c357";
export var headerMenuWrapper = "Header-module--headerMenuWrapper--93dcf";
export var headerMenuWrapperActive = "Header-module--headerMenuWrapperActive--0fc22";
export var headerWrapper = "Header-module--headerWrapper--22e55";
export var menuBackgroundOverlay = "Header-module--menuBackgroundOverlay--cc3ea";
export var menuBackgroundOverlayActive = "Header-module--menuBackgroundOverlayActive--2f252";