import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { ElvisIcon, IconName } from '../ElvisIcon'
import { Link, LinkType } from '../Link'
import * as css from './ActionBlock.module.scss'

interface ActionBlockProps {
  status?: 'Informativ' | 'Advarsel'
  icon?: IconName
  children: ReactNode
  linkText?: string
  url?: string
}

export const ActionBlock: FC<ActionBlockProps> = ({
  status = 'Informativ',
  icon,
  children,
  linkText,
  url,
}) => {
  const containerClasses = classnames(css.actionBlock, {
    [css.info]: status === 'Informativ',
    [css.warning]: status === 'Advarsel',
  })

  return (
    <div className={containerClasses}>
      {icon && (
        <div className={css.icon}>
          <ElvisIcon fontSize={48} iconName={icon} />
        </div>
      )}
      <div className={css.body}>{children}</div>
      {linkText && url && (
        <Link className={css.actionLink} type={LinkType.Action} href={url}>
          {linkText}
        </Link>
      )}
    </div>
  )
}

ActionBlock.displayName = 'ActionBlock'
