import React, { FC, PropsWithChildren } from 'react'
import { TermsProvider } from '@elvia/terms'
import { LocalizationProvider } from '@elvia/localization/src/useLocalization'
import { useTerms } from '../queries/useTerms'

interface Props {
  locale: string
}

const LocalizationProviders: FC<PropsWithChildren<Props>> = ({
  children,
  locale,
}) => {
  return (
    <LocalizationProvider initialLocale={locale}>
      <Terms>{children}</Terms>
    </LocalizationProvider>
  )
}

const Terms: FC<PropsWithChildren> = ({ children }) => {
  const terms = useTerms()

  return <TermsProvider initialTerms={terms}>{children}</TermsProvider>
}

export default LocalizationProviders
