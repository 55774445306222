import classnames from 'classnames'
import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react'

import { Button, ButtonType } from '../Button'
import { Link, LinkType } from '../Link'
import * as css from './HeaderButton.module.scss'
import { ElvisIcon } from '../ElvisIcon'

export interface HeaderButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  expanded?: boolean
  active?: boolean
  useArrow?: boolean
}

export const HeaderButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  HeaderButtonProps
>((props, ref) => {
  const {
    children,
    onClick,
    /**
     * If given url, returns a link. Can not be set together with onClick.
     */
    onFocus,
    href,
    disabled,
    expanded = false,
    active = false,
    useArrow = false,
    ...rest
  } = props
  const isButton = !href
  const isLink = href && !onClick
  if (!isButton && !isLink) {
    throw new Error(
      'HeaderButton can not have both `href` and `onClick` set at the same time.'
    )
  }

  if (isLink)
    return (
      <div
        className={classnames(css.headerbutton, css.asLink, {
          [css.active]: expanded,
        })}
      >
        <Link
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          type={LinkType.NoDecoration}
          href={href || ''}
        >
          {children}
        </Link>
      </div>
    )

  return (
    <Button
      ref={ref as ForwardedRef<HTMLButtonElement>}
      asType={ButtonType.Link}
      data-testid="HeaderButton"
      data-isheaderbutton="yes"
      tabIndex={0}
      onClick={e => !disabled && onClick?.(e)}
      onFocus={e => !disabled && onFocus?.(e)}
      className={classnames(
        'menuLabel',
        css.headerbutton,
        { [css.arrow]: useArrow },
        {
          [css.active]: active,
        }
      )}
      {...rest}
    >
      <span data-isheaderbutton="yes">{children}</span>
      {useArrow && (
        <span
          className={classnames(
            css.icon,
            { [css.iconUp]: expanded },
            { [css.iconDown]: !expanded }
          )}
        >
          <ElvisIcon iconName={'arrowUpBold'} fontSize={8} />
        </span>
      )}
    </Button>
  )
})

HeaderButton.displayName = 'HeaderButton'
