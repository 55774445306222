import classnames from 'classnames'
import React, { FC } from 'react'

import { TextImage } from '../Typography'
import * as css from './EmbeddedVideo.module.scss'

export interface EmbeddedVideoProps {
  title: string
  src: string
  description: string
  fullWidth?: boolean
  onPlay?: () => void
  isYouTubeVideo: boolean
}

export const EmbeddedVideo: FC<EmbeddedVideoProps> = ({
  title,
  src,
  description,
  fullWidth,
  onPlay,
  isYouTubeVideo,
}) => {
  return (
    <div
      className={classnames(css.videoContainer, { [css.fullWidth]: fullWidth })}
    >
      {isYouTubeVideo ? (
        <iframe title={title} src={src} frameBorder="0" allowFullScreen />
      ) : (
        <video
          width="100%"
          height="100%"
          preload="auto"
          title={title}
          src={src}
          controls
          onPlay={onPlay}
        />
      )}
      <TextImage className={css.videoDescription}>{description}</TextImage>
    </div>
  )
}
