import React, { FC, lazy } from 'react'
import { PowerOutageProvider } from './PowerOutageProvider'
import { ApiProvider } from './utils/ApiProvider'
const PowerOutageMap = lazy(() => import('./PowerOutageMap'))

const PowerOutage: FC = () => {
  const isSSR = typeof window === 'undefined'
  if (isSSR) {
    return ''
  }

  return (
    <React.Suspense fallback={<div />}>
      <ApiProvider>
        <PowerOutageProvider>
          <PowerOutageMap />
        </PowerOutageProvider>
      </ApiProvider>
    </React.Suspense>
  )
}

export const Head: FC = () => (
  <>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
    />
  </>
)

export default PowerOutage
