import React, { FC } from 'react'
import { TitleXSmall, TextSmall } from '@elvia/ui/src/components/Typography'
import { formatDayTitle } from '@elvia/ui/src/utils/formatDate'
import { ElvisIcon } from '@elvia/ui/src/components/ElvisIcon'
import { JobylonEntry } from './useJobylonApi'

import * as css from './Jobylon.module.scss'

interface Props {
  entry: JobylonEntry
}

const JobylonCard: FC<Props> = ({ entry }) => {
  return (
    <a
      href={entry.urls.ad}
      target="_blank"
      rel="noreferrer"
      className={css.jobylonCard}
    >
      <TitleXSmall>{entry.title}</TitleXSmall>
      <div>
        <ElvisIcon iconName="pin" fontSize={16} />
        <div>
          {entry.locations.map((location, index) => (
            <TextSmall key={location.location.city}>
              {location.location.city}
              {index + 1 !== entry.locations.length && ','}
            </TextSmall>
          ))}
        </div>
        <ElvisIcon iconName="calendar" fontSize={16} />
        <TextSmall>{formatDayTitle(entry.to_date, 'nb')}</TextSmall>
      </div>
      <p dangerouslySetInnerHTML={{ __html: entry.descr }} />
      <div className={css.arrow}>
        <ElvisIcon iconName="arrowLongRightBold" />
      </div>
    </a>
  )
}

export default JobylonCard
