// ======= IMPORTANT! =======
// All values must be valid sass values or the loader may break.

export const colors = {
  white: '#ffffff',
  black: '#000000',
  green: '#29d305',
  yellowSignal: '#ffff00',
  orangeSignal: '#ffa000',
  redSignal: '#ee0701',
  grey: '#262626',
  grey90: '#3b3b3b',
  grey80: '#515151',
  grey70: '#676767',
  grey60: '#7c7c7c',
  grey50: '#929292',
  grey40: '#a8a8a8',
  grey30: '#dbdbdb',
  grey20: '#d3d3d3',
  grey10: '#e9e9e9',
  grey05: '#f4f4f4',
  grey02: '#fafafa',
  greenApple: '#21ac04',
  violetGrape: '#490192',
  blueBerry: '#006ddb',
  purplePlum: '#b66dff',
  orangeMango: '#db6d00',
  redTomato: '#b90202',
  outline: '#0064fa',
  whiteOverlay: 'rgba(255, 255, 255, 0.4)',
  shadow3: 'rgba(0, 0, 0, 0.03)',
  shadow6: 'rgba(0, 0, 0, 0.06)',
  shadow8: 'rgba(0, 0, 0, 0.08)',
}

// Local var for setting up baseFont and spaceBase
export const baseFontSize = 16 // should also equal 1 rem on medium font settings in the browser

export const breakpoints = {
  mobile: 0,
  tablet: 768,
  desktop: 1024,
}

interface ZLayers {
  overCookieBanner: number
  overCookieBannerMinusOne: number
  top: number
  header: number
  headerBg: number
  formComponents: number
  base: number
  below: number
}

export const zLayers: ZLayers = {
  overCookieBanner: 2147483647,
  overCookieBannerMinusOne: 2147483646,
  top: 9999,
  header: 4,
  headerBg: 3,
  formComponents: 2,
  base: 1,
  below: -1,
}

export const z = (layer: keyof ZLayers): number => zLayers[layer]

export const mediaQueries = {
  mobileOnly: `only screen and (max-width: ${breakpoints.tablet}px)`,
  tablet: `only screen and (min-width: ${breakpoints.tablet}px)`,
  tabletOnly: `only screen and (min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.desktop}px)`,
  desktop: `only screen and (min-width: ${breakpoints.desktop}px)`,
  landscape: 'only screen and (orientation: landscape)',
  portrait: 'only screen and (orientation: portrait)',
}

export const spaceBase = 0.5

export const baseFont = {
  family: 'Red Hat Display',
  rem: 1,
  px: baseFontSize,
  size: '1rem',
}

export const maxWidth = '1440px'
export const articleMaxWidth = '700px'
export const emCalc = (pixels: number): string => `${pixels / baseFontSize}rem`
export const spaceCalc = (multiplier: number): string =>
  `${multiplier * spaceBase}rem`

export const marginOuter = {
  mobile: spaceBase * 0,
  tablet: spaceBase * 0,
  desktop: spaceBase * 8,
}

export const marginInner = {
  mobile: spaceBase * 2,
  tablet: spaceBase * 4,
  desktop: spaceBase * 15,
}

export const formWidthDesktop = '28rem'
export const formWidthTablet = '28rem'
export const formWidthMobile = '100%'

export const fontColors = {
  text: colors.black,
  textInverted: colors.white,
  headings: colors.black,
  headingsInverted: colors.white,
  link: colors.black,
  linkVisited: colors.black,
  linkInverted: colors.white,
  metaText: colors.black,
  metaTextInverted: colors.white,
}

export const borderRadius = {
  small: '4px',
  medium: '8px',
  large: '10px',
  veryLarge: '20px',
}

export default {
  colors,
  z,
  zLayers,
  breakpoints,
  mediaQueries,
  baseFont,
  fontColors,
  emCalc,
  spaceCalc,
  spaceBase,
  maxWidth,
  marginInner,
  marginOuter,
  borderRadius,
  formWidthMobile,
  formWidthTablet,
  formWidthDesktop,
}
