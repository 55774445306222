import React, { FC } from 'react'

import { Pagination as ElvisPagination } from '@elvia/elvis-pagination/react'

import { DropdownItem } from '../Dropdown/Dropdown'

export type PageSize = 10 | 20 | 30 | 40 | 50 | 99999

interface PaginationProps {
  updateTableCurrentPage: (number: number) => void
  updateTablePageSize: (size: PageSize) => void
  pageSize?: PageSize
  totalNumberOfResults: number
  textShows: string
  textOf: string
  textMeteringPoints: string
}

const pageSizeOptions = [
  { value: '10', label: '10' },
  { value: '20', label: '20' },
  { value: '30', label: '30' },
  { value: '40', label: '40' },
  { value: '50', label: '50' },
  { value: '99999', label: 'Alle' },
]

export const Pagination: FC<PaginationProps> = ({
  pageSize = 20,
  updateTableCurrentPage,
  updateTablePageSize,
  totalNumberOfResults,
  textShows,
  textOf,
  textMeteringPoints,
}) => {
  return (
    <ElvisPagination
      dropdownSelectedItemIndex={pageSizeOptions.findIndex(
        item => item.value === `${pageSize}`
      )}
      numberOfElements={totalNumberOfResults}
      alignment="right"
      labelOptions={{
        displaying: textShows,
        of: textOf,
        label: textMeteringPoints,
      }}
      dropdownItems={pageSizeOptions}
      dropdownSelectedItemIndexOnChange={index => {
        updateTablePageSize(
          +((pageSizeOptions[index] as DropdownItem)
            .value as number) as PageSize
        )
      }}
      valueOnChange={value => {
        if (!value) return
        const newPage = Math.ceil((value.end as number) / pageSize)
        updateTableCurrentPage(newPage)
      }}
    />
  )
}
