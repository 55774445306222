import React, { FC } from 'react'

import {
  Divider as ElvisDivider,
  DividerProps,
} from '@elvia/elvis-divider/react'

export const Divider: FC<DividerProps> = ({ ...rest }) => {
  return <ElvisDivider {...rest} />
}
