import { flatten } from 'ramda'
import React, { FC, ReactElement } from 'react'
import { MediaTypes, useMediaOnly } from '../hooks'

const RestructureTableData: FC<{ children: JSX.Element[] }> = ({
  children,
}) => {
  const isMobileOnly = useMediaOnly(MediaTypes.MOBILE)
  if (!isMobileOnly) {
    return children
  }

  const head = (children[0] as ReactElement).props?.children?.props?.children
  const body = flatten((children[1] as ReactElement).props?.children)
  const modifiedBody = body.map((bodyRow: ReactElement | false) => {
    return (
      bodyRow &&
      bodyRow.props.children.map((cell: ReactElement, cellIndex: number) => {
        if (bodyRow.key === 'expandableContent') {
          return (
            <tr key={cellIndex}>
              {cell.props.children[0]}
              {cell.props.children[cell.props.children.length - 1]}
            </tr>
          )
        }
        if (bodyRow.key === 'expandableButton') {
          return <tr key={cellIndex}>{cell}</tr>
        }
        if (cellIndex === 0) {
          return (
            <tr key={cellIndex}>
              <th colSpan={2}>{cell.props.children}</th>
            </tr>
          )
        }
        return cell.props.children ? (
          <tr key={cellIndex}>
            <td>{head[cellIndex].props.children}</td>
            {cell}
          </tr>
        ) : null
      })
    )
  })

  return (
    <>
      <thead>
        <tr>
          <th colSpan={2}>{head[0].props.children}</th>
        </tr>
      </thead>
      <tbody>{modifiedBody}</tbody>
    </>
  )
}

export default RestructureTableData
