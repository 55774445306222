import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react'
import { PowerOutage } from './hooks/usePowerOutage'

export type ViewType = 'detail' | 'list' | 'noPowerOutage' | 'outsideElviaArea'

interface Props {
  viewType: ViewType
  setViewType: (viewType: ViewType) => void
  selectedData: PowerOutage | undefined
  setSelectedData: (data: PowerOutage | undefined) => void
}

const PowerOutageContext = createContext<Props | undefined>(undefined)

interface PowerOutageProviderProps {
  children: ReactNode
}

export const PowerOutageProvider: FC<PowerOutageProviderProps> = ({
  children,
}: PowerOutageProviderProps) => {
  const [viewType, setViewType] = useState<ViewType>('list')
  const [selectedData, setSelectedData] = useState<PowerOutage>()

  return (
    <PowerOutageContext.Provider
      value={{ viewType, setViewType, selectedData, setSelectedData }}
    >
      {children}
    </PowerOutageContext.Provider>
  )
}

export const usePowerOutageContext = (): Props => {
  const context = useContext(PowerOutageContext)
  if (context === undefined) {
    throw new Error('usePowerOutage must be used within a PowerOutageProvider')
  }
  return context
}
