import classnames from 'classnames'
import React, { FC } from 'react'

import { Button, ButtonType } from '@elvia/ui/src/components/Button'
import { TextLarge, TitleSmall } from '@elvia/ui/src/components/Typography'
import { MediaTypes, useMedia } from '@elvia/ui/src/hooks/useMedia/useMedia'
import { openCookiePreferences } from '@elvia/ui/src/utils/openCookiePreferences'
import { EmptyInfoOnGreyColor } from '@elvia/icons'

import * as css from './EmbeddedVideoWrapper.module.scss'

export interface CookiesConsentTerms {
  header: string
  descriptionText: string
  buttonText: string
}

export const CookiesNotConsentedBox: FC<CookiesConsentTerms> = ({
  header,
  descriptionText,
  buttonText,
}) => {
  const isDesktop = useMedia(MediaTypes.DESKTOP)

  return (
    <div className={classnames(css.cookiesNotConsentedBox)}>
      <EmptyInfoOnGreyColor />
      <div className={css.messageText}>
        <TitleSmall>{header}</TitleSmall>
        <TextLarge>{descriptionText}</TextLarge>
      </div>
      <div className={css.settingsButton}>
        <Button
          asType={ButtonType.Primary}
          size={isDesktop ? 'large' : 'medium'}
          onClick={openCookiePreferences}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
