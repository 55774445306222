import * as React from 'react'
import * as css from './QuoteBlock.module.scss'
import classnames from 'classnames'
import { ElvisIcon } from '../ElvisIcon'
import { TextQuote } from '../Typography'

export interface QuoteBlockProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

export const QuoteBlock: React.FC<QuoteBlockProps> = ({
  children,
  className,
  style,
}) => {
  const classes = classnames(css.quoteblock, className)

  return (
    <TextQuote data-testid="QuoteBlock" className={classes} style={style}>
      <ElvisIcon iconName="quotationColor" />
      <div className={css.textContainer}>{children}</div>
    </TextQuote>
  )
}
