import classnames from 'classnames'
import * as React from 'react'

import { spaceCalc } from '../../theme'
import * as css from './Tooltip.module.scss'

export enum TipPosition {
  Top = 'Top',
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
}

export interface TooltipProps {
  children: React.ReactNode
  className?: string
  visible?: boolean
  width?: number
  tipPosition?: TipPosition
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  className,
  visible = false,
  width = 15,
  tipPosition = TipPosition.Bottom,
}) => {
  const classes = classnames(css.container, className)

  return (
    <div className={classes}>
      <span
        style={{ minWidth: spaceCalc(width) }}
        className={classnames(css.tooltip, {
          [css.visible]: visible === true,
          [css.tooltipBottom]: tipPosition === TipPosition.Bottom,
          [css.tooltipTop]: tipPosition === TipPosition.Top,
          [css.tooltipLeft]: tipPosition === TipPosition.Left,
          [css.tooltipRight]: tipPosition === TipPosition.Right,
        })}
      >
        {children}
      </span>
    </div>
  )
}
