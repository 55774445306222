import React, { FC, lazy } from 'react'
const Gobi = lazy(() => import('./Gobi'))

interface Props {
  storyIds: string[]
}

export const GobiStory: FC<Props> = ({ storyIds }) => {
  if (typeof window === 'undefined' || typeof document === 'undefined')
    return <div></div>
  return (
    <React.Suspense fallback={<div />}>
      <Gobi storyIds={storyIds} />
    </React.Suspense>
  )
}
