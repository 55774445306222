import classnames from 'classnames'
import { identity, equals, always, T, cond } from 'ramda'
import React, { Children, CSSProperties, FC, ReactNode } from 'react'

import { replace } from '../../utils/replace'
import * as css from './Typography.module.scss'

export enum TypographyType {
  TITLE_LARGE = 'e-title-lg',
  TITLE_MEDIUM = 'e-title-md',
  TITLE_SMALL = 'e-title-sm',
  TITLE_XSMALL = 'e-title-xs',
  TITLE_XXSMALL = 'e-title-xxs',
  TEXT_LEAD = 'e-text-lead',
  TEXT_LARGE = 'e-text-lg',
  TEXT_MEDIUM = 'e-text-md',
  TEXT_SMALL = 'e-text-sm',
  TEXT_OPTION = 'e-text-option',
  TEXT_QUOTE = 'e-text-quote',
  TEXT_BODY = 'e-text-body',
  TEXT_DESCRIPTION = 'e-text-description',
  TEXT_LABEL_INPUT = 'e-form-field__label',
  TEXT_MICRO = 'e-text-micro',
  TEXT_CAPS = 'e-text-caps',
  TEXT_IMAGE = 'e-text-img',
}

export enum TypographyTag {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  P = 'p',
  BLOCKQUOTE = 'blockquote',
  LABEL = 'label',
  LEGEND = 'legend',
  TABLE_CAPTION = 'caption',
  FIGURE_CAPTION = 'figcaption',
  SPAN = 'span',
}

export interface TypographyProps {
  children: ReactNode
  className?: string
  style?: CSSProperties
  /**
   * Titles:
   * TITLE_COVER, TITLE_PAGE, TITLE_LARGE, TITLE_MEDIUM,
   * TITLE_SMALL, TITLE_XSMALL, TITLE_PRE
   *
   * Texts:
   * TEXT_LEAD, TEXT_LARGE, TEXT_MEDIUM, TEXT_SMALL, TEXT_QUOTE,
   * TEXT_CAPTION, TEXT_BODY, TEXT_DESCRIPTION, TEXT_LABEL_INPUT,
   * TEXT_LABEL_BOX, TEXT_CAPS
   * @default none
   */
  type?: TypographyType
  /**
   * H1 - H6, p, label, legend, caption, blockquote, span
   * @default p
   */
  tag?: TypographyTag
  outline?: boolean
  coloredDots?: boolean
  useDots?: boolean
  light?: boolean
  centered?: boolean
  htmlFor?: string
}

const wrapDots = (classes: string[]) => (node: ReactNode) => {
  if (typeof node !== 'string') return node

  return replace(node, '.', key => (
    <span key={key} className={classes.join(' ')}>
      .
    </span>
  ))
}

interface Attributes extends JSX.IntrinsicAttributes {
  className?: string
  style?: CSSProperties
}

export const Typography: FC<TypographyProps> = ({
  children,
  className,
  style,
  type = '',
  tag: Tag = 'p',
  outline = false,
  coloredDots = false,
  useDots = true,
  light = false,
  centered = false,
  ...rest
}) => {
  const getTypographyType = cond([
    [equals(TypographyType.TITLE_XSMALL), always(css.eTitleXs)],
    [equals(TypographyType.TEXT_DESCRIPTION), always(css.eTextDescription)],
    [T, identity],
  ])

  const classes = classnames(className, {
    [getTypographyType(type as TypographyType) as string]: type !== '',
    [css.outline]: outline,
    [css.outlineLight]: outline && light,
    [css.outlineDark]: outline && !light,
    [css.centered]: centered,
  })
  const attrs: Attributes = {
    className: classes,
    style,
    ...rest,
  }

  return (
    <Tag {...attrs}>
      {useDots
        ? Children.map(children, wrapDots([coloredDots ? css.dot : '', 'dot']))
        : children}
    </Tag>
  )
}

// ==================== SHORTHAND =======================

export const TitleLarge: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.H1,
  ...rest
}) => {
  return (
    <Typography type={TypographyType.TITLE_LARGE} tag={tag} {...rest}>
      {children}
    </Typography>
  )
}

export const TitleMedium: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.H2,
  ...rest
}) => (
  <Typography type={TypographyType.TITLE_MEDIUM} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TitleSmall: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.H3,
  ...rest
}) => (
  <Typography type={TypographyType.TITLE_SMALL} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TitleXSmall: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.H4,
  ...rest
}) => (
  <Typography type={TypographyType.TITLE_XSMALL} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TitleXXSmall: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.H5,
  ...rest
}) => (
  <Typography type={TypographyType.TITLE_XXSMALL} tag={tag} {...rest}>
    {children}
  </Typography>
)

// ================ TEXTS ===================

export const TextLead: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.P,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_LEAD} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextLarge: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.P,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_LARGE} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextMedium: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.P,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_MEDIUM} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextSmall: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.P,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_SMALL} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextOption: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.P,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_OPTION} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextBody: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.P,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_BODY} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextDescription: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.P,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_DESCRIPTION} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextLabelInput: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.LABEL,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_LABEL_INPUT} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextMicro: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.P,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_MICRO} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextCaps: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.P,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_CAPS} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextImage: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.SPAN,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_IMAGE} tag={tag} {...rest}>
    {children}
  </Typography>
)

export const TextQuote: FC<TypographyProps> = ({
  children,
  tag = TypographyTag.BLOCKQUOTE,
  ...rest
}) => (
  <Typography type={TypographyType.TEXT_QUOTE} tag={tag} {...rest}>
    {children}
  </Typography>
)
