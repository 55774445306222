// extracted by mini-css-extract-plugin
export var eTable = "Table-module--e-table--8170e";
export var eTableMobile = "Table-module--e-table-mobile--6177a";
export var eTable__cellMultiline = "Table-module--e-table__cell--multiline--f7b8d";
export var innerTableContainer = "Table-module--inner-table-container--16afc";
export var limitedWidthTableContainer = "Table-module--limited-width-table-container--b7060";
export var morePadding = "Table-module--morePadding--e9ad5";
export var noZebra = "Table-module--no-zebra--68f23";
export var outerTableContainer = "Table-module--outer-table-container--5268e";
export var smallTable = "Table-module--smallTable--f8509";
export var smallTableIncludeIcons = "Table-module--smallTable-include-icons--5e03f";
export var smallTableZebra = "Table-module--smallTable-zebra--4ab46";