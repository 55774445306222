import classnames from 'classnames'
import React, {
  ReactNode,
  MouseEvent,
  ReactElement,
  CSSProperties,
  FC,
} from 'react'

import {
  useMedia,
  useMediaOnly,
  MediaTypes,
} from '../../hooks/useMedia/useMedia'
import * as css from './Table.module.scss'

export interface Icon {
  icon: ReactNode
  onClick: (id: string, e: MouseEvent) => void
}

export interface TableCellProps {
  key?: string
  value: ReactElement | string | number
  className?: string
}

export interface TableRow {
  id: string
  data: (string | ReactElement)[]
}

export interface TableProps {
  className?: string
  /**
   * If false, the table rows have alternating background color. If true, a standard border is used
   * to separate the rows with a single white background color for the whole table.
   */
  noZebra?: boolean
  /**
   * Determines size of table, in changing spacing and font size.
   * If true, smaller font and less spacing between rows.
   */
  smallTable?: boolean
  isLimitedWidth?: boolean
  morePadding?: boolean
  style?: CSSProperties
  children?: ReactNode
}

export const Table: FC<TableProps> = ({
  className,
  noZebra = false,
  style,
  smallTable,
  isLimitedWidth,
  morePadding,
  children,
}) => {
  const isTablet = useMedia(MediaTypes.TABLET)
  const isMobileOnly = useMediaOnly(MediaTypes.MOBILE)
  const table = (
    <table
      data-testid="Table"
      style={style}
      className={classnames(
        smallTable
          ? {
              [css.smallTable]: true,
              [css.smallTableZebra]: !noZebra,
              [css.morePadding]: morePadding,
              [className || '']: true,
            }
          : {
              [css.eTableMobile]: isMobileOnly,
              [css.eTable]: isTablet,
              [css.noZebra]: noZebra,
            }
      )}
    >
      {children}
    </table>
  )

  return smallTable ? (
    table
  ) : (
    <div
      className={classnames(
        css.outerTableContainer,
        {
          [css.limitedWidthTableContainer]: isLimitedWidth,
        },
        className
      )}
    >
      <div className={css.innerTableContainer}>{table}</div>
    </div>
  )
}
