import dayjs from 'dayjs'
import 'dayjs/locale/nb'
import React, { FC } from 'react'

import {
  Datepicker as ElvisDatepicker,
  DatepickerProps as ElvisDatepickerProps,
} from '@elvia/elvis-datepicker/react'

interface DatepickerProps
  extends Omit<
    ElvisDatepickerProps,
    'value' | 'valueOnChangeISOString' | 'valueOnChange'
  > {
  value?: string | null | Date
  onChange: (date: string | null) => void
}

export const Datepicker: FC<DatepickerProps> = ({
  isFullWidth = true,
  size = 'small',
  onChange,
  value,
  ...rest
}) => {
  return (
    <ElvisDatepicker
      size={size}
      isFullWidth={isFullWidth}
      valueOnChange={value => {
        const dateString = value ? dayjs(value).format('YYYY-MM-DD') : null
        onChange(dateString)
      }}
      value={value ? dayjs(value).toDate() : null}
      {...rest}
    />
  )
}
