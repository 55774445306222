// extracted by mini-css-extract-plugin
export var faqContainer = "FaqBlock-module--faqContainer--24a40";
export var faqContainerFirstElement = "FaqBlock-module--faqContainerFirstElement--0bd15";
export var faqContent = "FaqBlock-module--faqContent--a60be";
export var faqContentContainer = "FaqBlock-module--faqContentContainer--34b3d";
export var faqContentContainerHide = "FaqBlock-module--faqContentContainerHide--406d3";
export var faqHeader = "FaqBlock-module--faqHeader--77fa1";
export var faqHeaderArticle = "FaqBlock-module--faqHeaderArticle--20394";
export var faqIconContainer = "FaqBlock-module--faqIconContainer--f1aa7";
export var rotated = "FaqBlock-module--rotated--f331c";
export var textBody = "FaqBlock-module--text-body--c99e0";