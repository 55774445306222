// created by kunde-cli sync terms
import { ReactNode } from 'react'
import { RenderRichTextOptions } from './renderRichTextTypes'

export type Term = keyof typeof TermsEnum
export type Terms = Record<Term, string>
export type DynamicTerms = Record<Term, <T>(options: T[]) => string>
export type RichTerms = Record<
  Term,
  (options?: RenderRichTextOptions) => ReactNode
>
type FilterKeys<
  Limit extends Terms | DynamicTerms | RichTerms,
  Filter extends string
> = {
  [Key in keyof Limit]: Key extends Filter ? Key : never
}[keyof Limit]
type FilterTerms<
  Limit extends Terms | DynamicTerms | RichTerms,
  Filter extends string
> = {
  [Key in FilterKeys<Limit, Filter>]: Limit[Key]
}
export type TermsDictionary<Filter extends string = string> = {
  terms: FilterTerms<Terms, Filter>
  richTerms: FilterTerms<RichTerms, Filter>
  dynamicTerms: FilterTerms<DynamicTerms, Filter>
}
export enum TermsEnum {
  admin = 'admin',
  article = 'article',
  articleTimeToReadText = 'article-time-to-read-text',
  category = 'category',
  companyOwner = 'company-owner',
  companyReader = 'company-reader',
  companyAdmin = 'company-admin',
  consumption = 'consumption',
  contentLoaderErrorText = 'content-loader-error-text',
  contentLoaderErrorTitle = 'content-loader-error-title',
  day = 'day',
  defaultLinkTextRelatedContent = 'defaultLinkTextRelatedContent',
  embeddedVideoYoutubeCookieConsentButtonText = 'embedded-video-youtube-cookie-consent-button-text',
  embeddedVideoYoutubeCookieConsentDescription = 'embedded-video-youtube-cookie-consent-description',
  embeddedVideoYoutubeCookieConsentHeader = 'embedded-video-youtube-cookie-consent-header',
  faqBlockFeedbackDone = 'faq-block-feedback-done',
  faqBlockFeedbackQuestion = 'faq-block-feedback-question',
  faqBlockFeedbackSend = 'faq-block-feedback-send',
  faqBlockFeedbackTextareaDescription = 'faq-block-feedback-textarea-description',
  faqBlockFeedbackTextareaPlaceholder = 'faq-block-feedback-textarea-placeholder',
  faqBlockHeader = 'faq-block-header',
  fileuploadInfoText = 'fileupload-info-text',
  fileuploadLinkText = 'fileupload-link-text',
  fileuploadMaxFilesSizeError = 'fileupload-max-files-size-error',
  fileuploadMoreFilesText = 'fileupload-more-files-text',
  fileuploadRemoveText = 'fileupload-remove-text',
  fileuploadRequiredError = 'fileupload-required-error',
  footerCallComponent = 'footer-call-component',
  footerChatComponent = 'footer-chat-component',
  footerContactComponent = 'footer-contact-component',
  footerCookieText = 'footer-cookie-text',
  footerCookiesButtonText = 'footer-cookies-button-text',
  footerCookiesInfoText = 'footer-cookies-info-text',
  footerIntroduction = 'footer-introduction',
  forbruksappSigninInfo = 'forbruksapp-signin-info',
  form = 'form',
  formDefaultSuccessText = 'form-default-success-text',
  formRecaptcha = 'form-recaptcha',
  frontpageFallbackPageTitle = 'frontpage-fallback-page-title',
  hour = 'hour',
  jobylonAvailableJobs = 'jobylon-available-jobs',
  jobylonError = 'jobylon-error',
  jobylonFilterPlace = 'jobylon-filter-place',
  jobylonFilterPositionFunction = 'jobylon-filter-position-function',
  jobylonNoListings = 'jobylon-no-listings',
  jobylonSearchNoResult = 'jobylon-search-no-result',
  jobylonSearchPlaceholder = 'jobylon-search-placeholder',
  loginButton = 'login-button',
  loginPageBodyText = 'login-page-body-text',
  loginTitle = 'login-title',
  machineReaderOrganizationToken = 'machine-reader-organization-token',
  minSide = 'min-side',
  minSideAccessApprovalNoCustomerLinkText = 'min-side-access-approval-no-customer-link-text',
  minSideAccessApprovalNoCustomerText = 'min-side-access-approval-no-customer-text',
  minSideAccessApprovalNoCustomers = 'min-side-access-approval-no-customers',
  minSideAccessCardCorporateText = 'min-side-access-card-corporate-text',
  minSideAccessCardCorporateTitle = 'min-side-access-card-corporate-title',
  minSideAccessCardPrivateText = 'min-side-access-card-private-text',
  minSideAccessCardPrivateTitle = 'min-side-access-card-private-title',
  minSideAccessCorporateBreadcrumb = 'min-side-access-corporate-breadcrumb',
  minSideAccessDataBlockInfo = 'min-side-access-data-block-info',
  minSideAccessDataBlockTitle = 'min-side-access-data-block-title',
  minSideAccessDataPortabilityChooseMeteringPointFormError = 'min-side-access-data-portability-choose-metering-point-form-error',
  minSideAccessDataPortabilityChooseOrganizationFormError = 'min-side-access-data-portability-choose-organization-form-error',
  minSideAccessDataPortabilityChooseOrganizationFormTitle = 'min-side-access-data-portability-choose-organization-form-title',
  minSideAccessDataPortabilityDropdownPlaceholder = 'min-side-access-data-portability-dropdown-placeholder',
  minSideAccessDataPortabilityFormHeading = 'min-side-access-data-portability-form-heading',
  minSideAccessDataPortabilityFormInformation = 'min-side-access-data-portability-form-information',
  minSideAccessDataPortabilityGrantAccess = 'min-side-access-data-portability-grant-access',
  minSideAccessDataPortabilityModalInfo = 'min-side-access-data-portability-modal-info',
  minSideAccessDataPortabilityNoOrganizations = 'min-side-access-data-portability-no-organizations',
  minSideAccessDataPortabilityNotGranted = 'min-side-access-data-portability-not-granted',
  minSideAccessMobileTableHeader = 'min-side-access-mobile-table-header',
  minSideAccessPrivateBreadcrumb = 'min-side-access-private-breadcrumb',
  minSideAccessRequestApprovalAcceptButton = 'min-side-access-request-approval-accept-button',
  minSideAccessRequestApprovalApproveTextAfterUsername = 'min-side-access-request-approval-approve-text-after-username',
  minSideAccessRequestApprovalApproveTextBeforeUsername = 'min-side-access-request-approval-approve-text-before-username',
  minSideAccessRequestApprovalBackLink = 'min-side-access-request-approval-back-link',
  minSideAccessRequestApprovalDialogConfirmButton = 'min-side-access-request-approval-dialog-confirm-button',
  minSideAccessRequestApprovalDialogRejectTitle = 'min-side-access-request-approval-dialog-reject-title',
  minSideAccessRequestApprovalDialogTitle = 'min-side-access-request-approval-dialog-title',
  minSideAccessRequestApprovalErrorAlreadyHaveAccessText = 'min-side-access-request-approval-error-already-have-access-text',
  minSideAccessRequestApprovalErrorAlreadyHaveAccessTitle = 'min-side-access-request-approval-error-already-have-access-title',
  minSideAccessRequestApprovalInfo = 'min-side-access-request-approval-info',
  minSideAccessRequestApprovalOnlyOwnerText = 'min-side-access-request-approval-only-owner-text',
  minSideAccessRequestApprovalRejectButton = 'min-side-access-request-approval-reject-button',
  minSideAccessRequestApprovalRejectTextAfterUsername = 'min-side-access-request-approval-reject-text-after-username',
  minSideAccessRequestApprovalRejectTextBeforeUsername = 'min-side-access-request-approval-reject-text-before-username',
  minSideAccessRequestApprovalSuccessButton = 'min-side-access-request-approval-success-button',
  minSideAccessRequestApprovalSuccessText = 'min-side-access-request-approval-success-text',
  minSideAccessRequestApprovalSuccessTitle = 'min-side-access-request-approval-success-title',
  minSideAccessRequestApprovedText = 'min-side-access-request-approved-text',
  minSideAccessRequestBackButton = 'min-side-access-request-back-button',
  minSideAccessRequestCancelledText = 'min-side-access-request-cancelled-text',
  minSideAccessRequestCheckboxgroupError = 'min-side-access-request-checkboxgroup-error',
  minSideAccessRequestCheckboxgroupName = 'min-side-access-request-checkboxgroup-name',
  minSideAccessRequestCorporateErrorAlreadyHaveAccess = 'min-side-access-request-corporate-error-already-have-access',
  minSideAccessRequestCorporateFailedText = 'min-side-access-request-corporate-failed-text',
  minSideAccessRequestCorporateFailedTitle = 'min-side-access-request-corporate-failed-title',
  minSideAccessRequestCorporatePreamble = 'min-side-access-request-corporate-preamble',
  minSideAccessRequestCorporateSuccessButton = 'min-side-access-request-corporate-success-button',
  minSideAccessRequestCorporateSuccessFirstText = 'min-side-access-request-corporate-success-first-text',
  minSideAccessRequestCorporateSuccessLastText = 'min-side-access-request-corporate-success-last-text',
  minSideAccessRequestCorporateSuccessTitle = 'min-side-access-request-corporate-success-title',
  minSideAccessRequestCorporateTitle = 'min-side-access-request-corporate-title',
  minSideAccessRequestCorporateTooManyRequestsErrorText = 'min-side-access-request-corporate-too-many-requests-error-text',
  minSideAccessRequestCorporateTooManyRequestsErrorTitle = 'min-side-access-request-corporate-too-many-requests-error-title',
  minSideAccessRequestDialogSmsSentInfo = 'min-side-access-request-dialog-sms-sent-info',
  minSideAccessRequestDialogSmsSentPhonenumber = 'min-side-access-request-dialog-sms-sent-phonenumber',
  minSideAccessRequestDialogTitle = 'min-side-access-request-dialog-title',
  minSideAccessRequestFormRequestFailed = 'min-side-access-request-form-request-failed',
  minSideAccessRequestIconConfirmMobile = 'min-side-access-request-icon-confirm-mobile',
  minSideAccessRequestIconPaperSend = 'min-side-access-request-icon-paper-send',
  minSideAccessRequestIconWomanQuestionmark = 'min-side-access-request-icon-woman-questionmark',
  minSideAccessRequestIngressHasContracts = 'min-side-access-request-ingress-has-contracts',
  minSideAccessRequestPendingText = 'min-side-access-request-pending-text',
  minSideAccessRequestRejectedText = 'min-side-access-request-rejected-text',
  minSideAccessRequestRevokedText = 'min-side-access-request-revoked-text',
  minSideAccessRequestTableEmpty = 'min-side-access-request-table-empty',
  minSideAccessRequestTableHeaderFirstColumn = 'min-side-access-request-table-header-first-column',
  minSideAccessRequestTableHeaderSecondColumn = 'min-side-access-request-table-header-second-column',
  minSideAccessRequestTableMessageApproved = 'min-side-access-request-table-message-approved',
  minSideAccessRequestTableMessageCancelled = 'min-side-access-request-table-message-cancelled',
  minSideAccessRequestTableMessagePending = 'min-side-access-request-table-message-pending',
  minSideAccessRequestTableMessageRejected = 'min-side-access-request-table-message-rejected',
  minSideAccessRequestTableMessageRevoked = 'min-side-access-request-table-message-revoked',
  minSideAccessRequestTableTitle = 'min-side-access-request-table-title',
  minSideAccessRequestTitleHasContracts = 'min-side-access-request-title-has-contracts',
  minSideAccessRequestUserCancelledText = 'min-side-access-request-user-cancelled-text',
  minSideAccessUpgradeToCompanyAdmin = 'min-side-access-upgrade-to-company-admin',
  minSideAccessUpgradeToCompanyAdminDialogTitle = 'min-side-access-upgrade-to-company-admin-dialog-title',
  minSideAccessUpgradeToCompanyAdminDialogConfirm = 'min-side-access-upgrade-to-company-admin-dialog-confirm',
  minSideAccessesOverviewTokenPopoverText = 'min-side-accesses-overview-token-popover-text',
  minSideAccessesOverviewTokenPopoverTextCreatedBy = 'min-side-accesses-overview-token-popover-text-created-by',
  minSideAccessesOverviewTokenPopoverTextDuration = 'min-side-accesses-overview-token-popover-text-duration',
  minSideAccessesOverviewTokenPopoverTitle = 'min-side-accesses-overview-token-popover-title',
  minSideAccessesShareMeterValuesDashboardBlockBtnText = 'min-side-accesses-share-meter-values-dashboard-block-btn-text',
  minSideAccessesShareMeterValuesDashboardBlockInfoText = 'min-side-accesses-share-meter-values-dashboard-block-info-text',
  minSideAccessesShareMeterValuesDashboardBlockInfoTitle = 'min-side-accesses-share-meter-values-dashboard-block-info-title',
  minSideAccessesShareMeterValuesMeteringPointsError = 'min-side-accesses-share-meter-values-metering-points-error',
  minSideAccessesShareMeterValuesModalChooseMeteringPointsLabel = 'min-side-accesses-share-meter-values-modal-choose-metering-points-label',
  minSideAccessesShareMeterValuesModalChosenMeteringPointsLabel = 'min-side-accesses-share-meter-values-modal-chosen-metering-points-label',
  minSideAccessesShareMeterValuesModalCloseBtnText = 'min-side-accesses-share-meter-values-modal-close-btn-text',
  minSideAccessesShareMeterValuesModalCopyBtnText = 'min-side-accesses-share-meter-values-modal-copy-btn-text',
  minSideAccessesShareMeterValuesModalCreateBtnText = 'min-side-accesses-share-meter-values-modal-create-btn-text',
  minSideAccessesShareMeterValuesModalErrorText = 'min-side-accesses-share-meter-values-modal-error-text',
  minSideAccessesShareMeterValuesModalErrorTitle = 'min-side-accesses-share-meter-values-modal-error-title',
  minSideAccessesShareMeterValuesModalText = 'min-side-accesses-share-meter-values-modal-text',
  minSideAccessesShareMeterValuesModalTextareaFooterTextFirstPart = 'min-side-accesses-share-meter-values-modal-textarea-footer-text-first-part',
  minSideAccessesShareMeterValuesModalTextareaFooterTextLastPart = 'min-side-accesses-share-meter-values-modal-textarea-footer-text-last-part',
  minSideAccessesShareMeterValuesModalTitle = 'min-side-accesses-share-meter-values-modal-title',
  minSideAccessesShareMeterValuesModalTitleTokenGenerated = 'min-side-accesses-share-meter-values-modal-title-token-generated',
  minSideAccessesShareMeterValuesModalTokenNameLabel = 'min-side-accesses-share-meter-values-modal-token-name-label',
  minSideAccessesShareMeterValuesModalTokenNamePlaceholder = 'min-side-accesses-share-meter-values-modal-token-name-placeholder',
  minSideAccessesShareMeterValuesModalTooltipTextCopied = 'min-side-accesses-share-meter-values-modal-tooltip-text-copied',
  minSideAccessesShareMeterValuesNameError = 'min-side-accesses-share-meter-values-name-error',
  minSideActionAccessDescription = 'min-side-action-access-description',
  minSideActionConsumptionDescription = 'min-side-action-consumption-description',
  minSideActionEarthFault = 'min-side-action-earth-fault',
  minSideActionEarthFaultDescription = 'min-side-action-earth-fault-description',
  minSideActionHanportDescription = 'min-side-action-hanport-description',
  minSideActionInvoiceDescription = 'min-side-action-invoice-description',
  minSideActionNotificationDescription = 'min-side-action-notification-description',
  minSideActionProductionSharing = 'min-side-action-production-sharing',
  minSideActionProductionSharingDescription = 'min-side-action-production-sharing-description',
  minSideAddress = 'min-side-address',
  minSideAverageMaxHourPlotLineTooltip = 'min-side-average-max-hour-plot-line-tooltip',
  minSideAveragePlotLineLabel = 'min-side-average-plot-line-label',
  minSideBackButton = 'min-side-back-button',
  minSideButtonCancel = 'min-side-button-cancel',
  minSideCalculatedCostGraphInfoText = 'min-side-calculated-cost-graph-info-text',
  minSideCalculatedCostGraphInfoTitle = 'min-side-calculated-cost-graph-info-title',
  minSideCalculatedCostGraphTitle = 'min-side-calculated-cost-graph-title',
  minSideCalculatedFixedPriceAverage = 'min-side-calculated-fixed-price-average',
  minSideCalculatedMaxHoursGraphEmptyText = 'min-side-calculated-max-hours-graph-empty-text',
  minSideCalculatedMaxHoursGraphEmptyTitle = 'min-side-calculated-max-hours-graph-empty-title',
  minSideCalculatedMaxHoursGraphErrorText = 'min-side-calculated-max-hours-graph-error-text',
  minSideChooseCustomer = 'min-side-choose-customer',
  minSideCompareTypeRadioButtonsGroupTitle = 'min-side-compare-type-radio-buttons-group-title',
  minSideCompareTypeRadioButtonsNothing = 'min-side-compare-type-radio-buttons-nothing',
  minSideCompareTypeRadioButtonsPreviousYears = 'min-side-compare-type-radio-buttons-previous-years',
  minSideCompareTypeRadioButtonsSpotPrice = 'min-side-compare-type-radio-buttons-spot-price',
  minSideCompensationEmptyStateText = 'min-side-compensation-empty-state-text',
  minSideCompensationEmptyStateTitle = 'min-side-compensation-empty-state-title',
  minSideCompensationErrorText = 'min-side-compensation-error-text',
  minSideCompensationExplanationAvgSpotPrice = 'min-side-compensation-explanation-avg-spot-price',
  minSideCompensationExplanationCompensationDegree = 'min-side-compensation-explanation-compensation-degree',
  minSideCompensationExplanationConsumption = 'min-side-compensation-explanation-consumption',
  minSideCompensationExplanationFormula = 'min-side-compensation-explanation-formula',
  minSideCompensationExplanationListTitle = 'min-side-compensation-explanation-list-title',
  minSideCompensationExplanationMaxConsumption = 'min-side-compensation-explanation-max-consumption',
  minSideCompensationExplanationMva = 'min-side-compensation-explanation-mva',
  minSideCompensationExplanationPriceThreshold = 'min-side-compensation-explanation-price-threshold',
  minSideCompensationExplanationTableTitle = 'min-side-compensation-explanation-table-title',
  minSideCompensationExplanationTitle = 'min-side-compensation-explanation-title',
  minSideCompensationExplanationVat = 'min-side-compensation-explanation-vat',
  minSideCompensationGraphDay = 'min-side-compensation-graph-day',
  minSideCompensationGraphMonth = 'min-side-compensation-graph-month',
  minSideCompensationGraphNight = 'min-side-compensation-graph-night',
  minSideCompensationGraphPopoverText = 'min-side-compensation-graph-popover-text',
  minSideCompensationGraphPopoverTitle = 'min-side-compensation-graph-popover-title',
  minSideCompensationGraphTitle = 'min-side-compensation-graph-title',
  minSideCompensationGraphWeekend = 'min-side-compensation-graph-weekend',
  minSideCompensationGraphYear = 'min-side-compensation-graph-year',
  minSideCompensationRequestSubmitButton = 'min-side-compensation-request-submit-button',
  minSideCompensationRequestText = 'min-side-compensation-request-text',
  minSideCompensationRequestTitle = 'min-side-compensation-request-title',
  minSideCompensationRequestTitleSuccess = 'min-side-compensation-request-title-success',
  minSideConsumption = 'min-side-consumption',
  minSideConsumptionHeadline = 'min-side-consumption-headline',
  minSideConsumptionKilowattHour = 'min-side-consumption-kilowatt-hour',
  minSideConsumptionPriceText = 'min-side-consumption-price-text',
  minSideCostComparisonInfoBoxEnergyPriceTableHeader = 'min-side-cost-comparison-info-box-energy-price-table-header',
  minSideCostComparisonInfoBoxFixedLevelTableHeader = 'min-side-cost-comparison-info-box-fixed-level-table-header',
  minSideCustomerGreeting = 'min-side-customer-greeting',
  minSideCustomerHasNoInvoices = 'min-side-customer-has-no-invoices',
  minSideCustomerOverviewNumbers = 'min-side-customer-overview-numbers',
  minSideCustomerOverviewText = 'min-side-customer-overview-text',
  minSideCustomerProfile = 'min-side-customer-profile',
  minSideCustomerViewRelationship = 'min-side-customer-view-relationship',
  minSideDashboardInvoiceShowAllLink = 'min-side-dashboard-invoice-show-all-link',
  minSideDashboardInvoiceTitle = 'min-side-dashboard-invoice-title',
  minSideDashboardManualReadingTitle = 'min-side-dashboard-manual-reading-title',
  minSideDashboardMeteringPointDescription = 'min-side-dashboard-metering-point-description',
  minSideDashboardMeteringPointDescriptionTitle = 'min-side-dashboard-metering-point-description-title',
  minSideDashboardMeteringPointTitle = 'min-side-dashboard-metering-point-title',
  minSideDashboardTotalCostDescription = 'min-side-dashboard-total-cost-description',
  minSideDayTime = 'min-side-day-time',
  minSideDisplayOptionButtonText = 'min-side-display-option-button-text',
  minSideDownloadConsumptionButtonText = 'min-side-download-consumption-button-text',
  minSideDownloadConsumptionCustomDateRangeRadioBtn = 'min-side-download-consumption-custom-date-range-radio-btn',
  minSideDownloadConsumptionDefaultDateRangeRadioBtn = 'min-side-download-consumption-default-date-range-radio-btn',
  minSideDownloadConsumptionFormatOptionTitle = 'min-side-download-consumption-format-option-title',
  minSideDownloadConsumptionGranularityOptionsTitle = 'min-side-download-consumption-granularity-options-title',
  minSideDownloadConsumptionPopoverBtnText = 'min-side-download-consumption-popover-btn-text',
  minSideDownloadConsumptionTimeOptionTitle = 'min-side-download-consumption-time-option-title',
  minSideEarthFaultAddress = 'min-side-earth-fault-address',
  minSideEarthFaultCubitCaseNumber = 'min-side-earth-fault-cubit-case-number',
  minSideEarthFaultCubitPinCode = 'min-side-earth-fault-cubit-pin-code',
  minSideEarthFaultExtendPeriodButton = 'min-side-earth-fault-extend-period-button',
  minSideEarthFaultExtendPeriodDeadline = 'min-side-earth-fault-extend-period-deadline',
  minSideEarthFaultExtendPeriodError = 'min-side-earth-fault-extend-period-error',
  minSideEarthFaultExtendPeriodModalHeader = 'min-side-earth-fault-extend-period-modal-header',
  minSideEarthFaultExtendPeriodModalText = 'min-side-earth-fault-extend-period-modal-text',
  minSideEarthFaultFixInfo1 = 'min-side-earth-fault-fix-info-1',
  minSideEarthFaultFixInfo2 = 'min-side-earth-fault-fix-info-2',
  minSideEarthFaultFixTitle = 'min-side-earth-fault-fix-title',
  minSideEarthFaultGraphLegend = 'min-side-earth-fault-graph-legend',
  minSideEarthFaultListNoTickets = 'min-side-earth-fault-list-no-tickets',
  minSideEarthFaultListTickets = 'min-side-earth-fault-list-tickets',
  minSideEarthFaultMpid = 'min-side-earth-fault-mpid',
  minSideEarthFaultMyEarthFaultButton = 'min-side-earth-fault-my-earth-fault-button',
  minSideEarthFaultMyEarthFaultTitle = 'min-side-earth-fault-my-earth-fault-title',
  minSideEarthFaultNotificationMessage = 'min-side-earth-fault-notification-message',
  minSideEarthFaultNotificationMessageButton = 'min-side-earth-fault-notification-message-button',
  minSideEarthFaultNotificationMessageRectifyReceived = 'min-side-earth-fault-notification-message-rectify-received',
  minSideEarthFaultNotificationMessageReminded = 'min-side-earth-fault-notification-message-reminded',
  minSideEarthFaultRectifyBack = 'min-side-earth-fault-rectify-back',
  minSideEarthFaultRectifyError = 'min-side-earth-fault-rectify-error',
  minSideEarthFaultRectifyInfoBody = 'min-side-earth-fault-rectify-info-body',
  minSideEarthFaultRectifyInfoTitle = 'min-side-earth-fault-rectify-info-title',
  minSideEarthFaultRectifyNext = 'min-side-earth-fault-rectify-next',
  minSideEarthFaultRectifySteper = 'min-side-earth-fault-rectify-steper',
  minSideEarthFaultRectifySubmit = 'min-side-earth-fault-rectify-submit',
  minSideEarthFaultRectifySuccess = 'min-side-earth-fault-rectify-success',
  minSideEarthFaultRectifyTitle = 'min-side-earth-fault-rectify-title',
  minSideEarthFaultRegisteredEarthFaultIngress = 'min-side-earth-fault-registered-earth-fault-ingress',
  minSideEarthFaultRegisteredEarthFaultTitle = 'min-side-earth-fault-registered-earth-fault-title',
  minSideEarthFaultRegistrationStatusHasEarthFault = 'min-side-earth-fault-registration-status-has-earth-fault',
  minSideEarthFaultRegistrationStatusNoEarthFault = 'min-side-earth-fault-registration-status-no-earth-fault',
  minSideEarthFaultRegistrationStatusTitle = 'min-side-earth-fault-registration-status-title',
  minSideEarthFaultRegistrationStatusUpdateExpectationText = 'min-side-earth-fault-registration-status-update-expectation-text',
  minSideEarthFaultRegistrationTimestampStart = 'min-side-earth-fault-registration-timestamp-start',
  minSideEarthFaultRegistrationTimestampStop = 'min-side-earth-fault-registration-timestamp-stop',
  minSideEarthFaultRegistrationTitle = 'min-side-earth-fault-registration-title',
  minSideEarthFaultTicketStatus = 'min-side-earth-fault-ticket-status',
  minSideEarthFaultTicketStatusManual = 'min-side-earth-fault-ticket-status-manual',
  minSideEarthFaultTicketStatusOpen = 'min-side-earth-fault-ticket-status-open',
  minSideEarthFaultTicketStatusRectify = 'min-side-earth-fault-ticket-status-rectify',
  minSideEarthFaultTicketStatusReminder = 'min-side-earth-fault-ticket-status-reminder',
  minSideEarthFaultTicketStatusUnknown = 'min-side-earth-fault-ticket-status-unknown',
  minSideEarthFaultWhatIsEarthFaultButton = 'min-side-earth-fault-what-is-earth-fault-button',
  minSideEarthFaultWhatIsEarthFaultInfo = 'min-side-earth-fault-what-is-earth-fault-info',
  minSideEarthFaultWhatIsEarthFaultTitle = 'min-side-earth-fault-what-is-earth-fault-title',
  minSideEarthFaultWhatToDoStep1 = 'min-side-earth-fault-what-to-do-step-1',
  minSideEarthFaultWhatToDoStep2 = 'min-side-earth-fault-what-to-do-step-2',
  minSideEarthFaultWhatToDoStep3 = 'min-side-earth-fault-what-to-do-step-3',
  minSideEarthFaultWhatToDoTitle = 'min-side-earth-fault-what-to-do-title',
  minSideEmptyValuePlaceholder = 'min-side-empty-value-placeholder',
  minSideEstimatedCompensation = 'min-side-estimated-compensation',
  minSideEstimatedCostHistorical = 'min-side-estimated-cost-historical',
  minSideEstimatedCostNoPrice = 'min-side-estimated-cost-no-price',
  minSideExtraTextFootnoteOrganizations = 'min-side-extra-text-footnote-organizations',
  minSideFixedPriceLevel = 'min-side-fixed-price-level',
  minSideGenericErrorText = 'min-side-generic-error-text',
  minSideGraphDaylightSavingTimeSummerTooltip = 'min-side-graph-daylight-saving-time-summer-tooltip',
  minSideGraphDaylightSavingTimeWinterTooltip = 'min-side-graph-daylight-saving-time-winter-tooltip',
  minSideGraphError = 'min-side-graph-error',
  minSideGraphIsNotAms = 'min-side-graph-is-not-ams',
  minSideGraphNoContractData = 'min-side-graph-no-contract-data',
  minSideGraphNoData = 'min-side-graph-no-data',
  minSideGraphRadioFilterOptionsCalculatedCost = 'min-side-graph-radio-filter-options-calculated-cost',
  minSideGraphRadioFilterOptionsCompensation = 'min-side-graph-radio-filter-options-compensation',
  minSideGraphRadioFilterOptionsProduction = 'min-side-graph-radio-filter-options-production',
  minSideGraphSpotPriceAverageTooltip = 'min-side-graph-spot-price-average-tooltip',
  minSideGraphSpotPriceCreditLabel = 'min-side-graph-spot-price-credit-label',
  minSideGraphSpotPriceTooltip = 'min-side-graph-spot-price-tooltip',
  minSideGraphTemperatureLabel = 'min-side-graph-temperature-label',
  minSideGraphTemperatureTooltip = 'min-side-graph-temperature-tooltip',
  minSideGraphWeatherCreditLabel = 'min-side-graph-weather-credit-label',
  minSideHanOrderAsideContentText = 'min-side-han-order-aside-content-text',
  minSideHanOrderBackToHanPortLink = 'min-side-han-order-back-to-han-port-link',
  minSideHanOrderCompliance = 'min-side-han-order-compliance',
  minSideHanOrderComplianceError = 'min-side-han-order-compliance-error',
  minSideHanOrderConfirmationMainTitle = 'min-side-han-order-confirmation-main-title',
  minSideHanOrderConfirmationSubTitle = 'min-side-han-order-confirmation-sub-title',
  minSideHanOrderConfirmationTableAddress = 'min-side-han-order-confirmation-table-address',
  minSideHanOrderConfirmationTableMeterSerialNumber = 'min-side-han-order-confirmation-table-meter-serial-number',
  minSideHanOrderConfirmationTableMeterSerialNumberShort = 'min-side-han-order-confirmation-table-meter-serial-number-short',
  minSideHanOrderConfirmationTableMeteringPointId = 'min-side-han-order-confirmation-table-metering-point-id',
  minSideHanOrderConfirmationText = 'min-side-han-order-confirmation-text',
  minSideHanOrderFormInvalidError = 'min-side-han-order-form-invalid-error',
  minSideHanOrderFormInvalidErrorTitle = 'min-side-han-order-form-invalid-error-title',
  minSideHanOrderFormSystemError = 'min-side-han-order-form-system-error',
  minSideHanOrderListCreatedDateHeader = 'min-side-han-order-list-created-date-header',
  minSideHanOrderListPostalCodeAndCityHeader = 'min-side-han-order-list-postal-code-and-city-header',
  minSideHanOrderListStatusHeader = 'min-side-han-order-list-status-header',
  minSideHanPort = 'min-side-han-port',
  minSideHanPortCloseButtonText = 'min-side-han-port-close-button-text',
  minSideHanPortCloseHanPortDialogMeterSerialNumber = 'min-side-han-port-close-han-port-dialog-meter-serial-number',
  minSideHanPortCloseHanPortDialogText = 'min-side-han-port-close-han-port-dialog-text',
  minSideHanPortCloseHanPortSuccessToastMeterSerialNumber = 'min-side-han-port-close-han-port-success-toast-meter-serial-number',
  minSideHanPortCloseHanPortSuccessToastProcessing = 'min-side-han-port-close-han-port-success-toast-processing',
  minSideHanPortDialogTitle = 'min-side-han-port-dialog-title',
  minSideHanPortEmptyState = 'min-side-han-port-empty-state',
  minSideHanPortLandisGyrMeteringPointAlert = 'min-side-han-port-landis-gyr-metering-point-alert',
  minSideHanPortMeterSearchPlaceholder = 'min-side-han-port-meter-search-placeholder',
  minSideHanPortNoSearchResultText = 'min-side-han-port-no-search-result-text',
  minSideHanPortNoSearchResultTitle = 'min-side-han-port-no-search-result-title',
  minSideHanPortOpenButtonText = 'min-side-han-port-open-button-text',
  minSideHanPortOpenHanPortDialogText = 'min-side-han-port-open-han-port-dialog-text',
  minSideHanPortStatusClosed = 'min-side-han-port-status-closed',
  minSideHanPortStatusClosingRequested = 'min-side-han-port-status-closing-requested',
  minSideHanPortStatusNoInfo = 'min-side-han-port-status-no-info',
  minSideHanPortStatusOpen = 'min-side-han-port-status-open',
  minSideHanPortStatusOpeningRequested = 'min-side-han-port-status-opening-requested',
  minSideInsightAveragePriceTitle = 'min-side-insight-average-price-title',
  minSideInsightEmptyState = 'min-side-insight-empty-state',
  minSideInsightEmptyStateButton = 'min-side-insight-empty-state-button',
  minSideInsightEmptyStateComparison = 'min-side-insight-empty-state-comparison',
  minSideInsightEnergyPriceText = 'min-side-insight-energy-price-text',
  minSideInsightEnergyPriceTitle = 'min-side-insight-energy-price-title',
  minSideInsightFixedLevelText = 'min-side-insight-fixed-level-text',
  minSideInvoiceBackButton = 'min-side-invoice-back-button',
  minSideInvoiceErrorText = 'min-side-invoice-error-text',
  minSideInvoicePageDescription = 'min-side-invoice-page-description',
  minSideInvoicePageTitle = 'min-side-invoice-page-title',
  minSideInvoicePassthroughPowerSupplier = 'min-side-invoice-passthrough-power-supplier',
  minSideInvoiceTabDescription = 'min-side-invoice-tab-description',
  minSideInvoiceTableHeaderAmount = 'min-side-invoice-table-header-amount',
  minSideInvoiceTableHeaderDownload = 'min-side-invoice-table-header-download',
  minSideInvoiceTableHeaderId = 'min-side-invoice-table-header-id',
  minSideInvoiceTableHeaderPeriod = 'min-side-invoice-table-header-period',
  minSideInvoiceTableHeaderStatus = 'min-side-invoice-table-header-status',
  minSideInvoiceTableNok = 'min-side-invoice-table-nok',
  minSideInvoiceTableStatusBilled = 'min-side-invoice-table-status-billed',
  minSideInvoiceTableStatusCredited = 'min-side-invoice-table-status-credited',
  minSideInvoiceTableStatusDebtcollection = 'min-side-invoice-table-status-debtcollection',
  minSideInvoiceTableStatusOsb = 'min-side-invoice-table-status-osb',
  minSideInvoiceTableStatusPaid = 'min-side-invoice-table-status-paid',
  minSideInvoiceTableStatusUnpaid = 'min-side-invoice-table-status-unpaid',
  minSideLabelFeatureComing = 'min-side-label-feature-coming',
  minSideLoading = 'min-side-loading',
  minSideManualReadingAlert = 'min-side-manual-reading-alert',
  minSideManualReadingErrorGeneric = 'min-side-manual-reading-error-generic',
  minSideManualReadingLabel = 'min-side-manual-reading-label',
  minSideManualReadingPlaceholder = 'min-side-manual-reading-placeholder',
  minSideManualReadingSuccess = 'min-side-manual-reading-success',
  minSideManualReadingSuccessButtonText = 'min-side-manual-reading-success-button-text',
  minSideManualSubmitButton = 'min-side-manual-submit-button',
  minSideMaxHoursGraphPopoverText = 'min-side-max-hours-graph-popover-text',
  minSideMaxHoursGraphPopoverTitle = 'min-side-max-hours-graph-popover-title',
  minSideMaxHoursGraphTitle = 'min-side-max-hours-graph-title',
  minSideMaxHoursUsedInCalculation = 'min-side-max-hours-used-in-calculation',
  minSideMaxHoursViewTypeOption = 'min-side-max-hours-view-type-option',
  minSideMenuCantFindCustomerRelationship = 'min-side-menu-cant-find-customer-relationship',
  minSideMenuNavigateText = 'min-side-menu-navigate-text',
  minSideMenuSelected = 'min-side-menu-selected',
  minSideMenuUseCustomerRelationship = 'min-side-menu-use-customer-relationship',
  minSideMeterAddress = 'min-side-meter-address',
  minSideMeterNoSearchResult = 'min-side-meter-no-search-result',
  minSideMeterSearchLabel = 'min-side-meter-search-label',
  minSideMeterSearchPlaceholder = 'min-side-meter-search-placeholder',
  minSideMeterSerialNumber = 'min-side-meter-serial-number',
  minSideMeterSerialNumberShort = 'min-side-meter-serial-number-short',
  minSideMeterValueTypeRadioButtonsGroupTitle = 'min-side-meter-value-type-radio-buttons-group-title',
  minSideMeterZipcode = 'min-side-meter-zipcode',
  minSideMeteringPointActive = 'min-side-metering-point-active',
  minSideMeteringPointAlertMessage = 'min-side-metering-point-alert-message',
  minSideMeteringPointAlertTitle = 'min-side-metering-point-alert-title',
  minSideMeteringPointCombinedGraphTypeInfoText = 'min-side-metering-point-combined-graph-type-info-text',
  minSideMeteringPointCombinedGraphTypeInfoTitle = 'min-side-metering-point-combined-graph-type-info-title',
  minSideMeteringPointCombinedGraphTypeTitle = 'min-side-metering-point-combined-graph-type-title',
  minSideMeteringPointConsumptionGraphTypeInfoText = 'min-side-metering-point-consumption-graph-type-info-text',
  minSideMeteringPointConsumptionGraphTypeInfoTitle = 'min-side-metering-point-consumption-graph-type-info-title',
  minSideMeteringPointConsumptionGraphTypeTitle = 'min-side-metering-point-consumption-graph-type-title',
  minSideMeteringPointCurrentPowerSupplier = 'min-side-metering-point-current-power-supplier',
  minSideMeteringPointDescription = 'min-side-metering-point-description',
  minSideMeteringPointHistorical = 'min-side-metering-point-historical',
  minSideMeteringPointId = 'min-side-metering-point-id',
  minSideMeteringPointLastActivePowerSupplier = 'min-side-metering-point-last-active-power-supplier',
  minSideMeteringPointNoPowerSupplier = 'min-side-metering-point-no-power-supplier',
  minSideMeteringPointNoPowerSupplierInfo = 'min-side-metering-point-no-power-supplier-info',
  minSideMeteringPointPageBackButton = 'min-side-metering-point-page-back-button',
  minSideMeteringPointPowerSupplier = 'min-side-metering-point-power-supplier',
  minSideMeteringPointPowerSupplierInfo = 'min-side-metering-point-power-supplier-info',
  minSideMeteringPointPowerSupplierInfoObligatedDelivery = 'min-side-metering-point-power-supplier-info-obligated-delivery',
  minSideMeteringPointPowerSupplierNoActive = 'min-side-metering-point-power-supplier-no-active',
  minSideMeteringPointPowerSupplierNow = 'min-side-metering-point-power-supplier-now',
  minSideMeteringPointPreviousPowerSupplier = 'min-side-metering-point-previous-power-supplier',
  minSideMeteringPointProductionGraphTypeInfoText = 'min-side-metering-point-production-graph-type-info-text',
  minSideMeteringPointProductionGraphTypeInfoTitle = 'min-side-metering-point-production-graph-type-info-title',
  minSideMeteringPointProductionGraphTypeTitle = 'min-side-metering-point-production-graph-type-title',
  minSideMeteringPointsWithHanPortTitle = 'min-side-metering-points-with-han-port-title',
  minSideMyInformation = 'min-side-my-information',
  minSideNewCompensationExplanationCompensationDegree = 'min-side-new-compensation-explanation-compensation-degree',
  minSideNewCompensationExplanationFormula = 'min-side-new-compensation-explanation-formula',
  minSideNewCompensationExplanationListTitle = 'min-side-new-compensation-explanation-list-title',
  minSideNewTariffPricesEnergyDayLabel = 'min-side-new-tariff-prices-energy-day-label',
  minSideNewTariffPricesEnergyNightLabel = 'min-side-new-tariff-prices-energy-night-label',
  minSideNewTariffPricesFixedLevelLabel = 'min-side-new-tariff-prices-fixed-level-label',
  minSideNewTariffPricesFootnote = 'min-side-new-tariff-prices-footnote',
  minSideNewTariffPricesText = 'min-side-new-tariff-prices-text',
  minSideNewTariffPricesTitle = 'min-side-new-tariff-prices-title',
  minSideNightWeekendTime = 'min-side-night-weekend-time',
  minSideNoCompensationCurrentMonthText = 'min-side-no-compensation-current-month-text',
  minSideNoCompensationCurrentMonthTitle = 'min-side-no-compensation-current-month-title',
  minSideNoCompensationMonthText = 'min-side-no-compensation-month-text',
  minSideNoCompensationMonthTitle = 'min-side-no-compensation-month-title',
  minSideNoContractsError = 'min-side-no-contracts-error',
  minSideNoCustomerRelationCorporateText = 'min-side-no-customer-relation-corporate-text',
  minSideNoCustomerRelationPrivateText = 'min-side-no-customer-relation-private-text',
  minSideNoNewCompensationCurrentMonthDynamicText = 'min-side-no-new-compensation-current-month-dynamic-text',
  minSideNoNewCompensationMonthDynamicText = 'min-side-no-new-compensation-month-dynamic-text',
  minSideNok = 'min-side-nok',
  minSideNotificationInfoDescription = 'min-side-notification-info-description',
  minSideNotificationInfoDescriptionTitle = 'min-side-notification-info-description-title',
  minSideNotificationTabDescription = 'min-side-notification-tab-description',
  minSideNotificationsFormChooseLabel = 'min-side-notifications-form-choose-label',
  minSideNotificationsFormInfo = 'min-side-notifications-form-info',
  minSideNotificationsFormSuccess = 'min-side-notifications-form-success',
  minSideNotificationsInfo = 'min-side-notifications-info',
  minSideNotificationsModalHeadingSuccess = 'min-side-notifications-modal-heading-success',
  minSideNotificationsType = 'min-side-notifications-type',
  minSideOrderOpeningHanPortTitle = 'min-side-order-opening-han-port-title',
  minSideOtherActions = 'min-side-other-actions',
  minSideOverviewTabDescription = 'min-side-overview-tab-description',
  minSidePercentage = 'min-side-percentage',
  minSidePowerOutage = 'min-side-power-outage',
  minSidePowerOutageBack = 'min-side-power-outage-back',
  minSidePowerOutageCauseKnown = 'min-side-power-outage-cause-known',
  minSidePowerOutageDiscovered = 'min-side-power-outage-discovered',
  minSidePowerOutageErrorFallback = 'min-side-power-outage-error-fallback',
  minSidePowerOutageListItemLabe = 'min-side-power-outage-list-item-labe',
  minSidePowerOutageMaintenance = 'min-side-power-outage-maintenance',
  minSidePowerOutageMunicipality = 'min-side-power-outage-municipality',
  minSidePowerOutageNoOutage = 'min-side-power-outage-no-outage',
  minSidePowerOutageNoPowerOutages = 'min-side-power-outage-no-power-outages',
  minSidePowerOutageNoPowerOutagesLinkText = 'min-side-power-outage-no-power-outages-link-text',
  minSidePowerOutageNotLocateUser = 'min-side-power-outage-not-locate-user',
  minSidePowerOutageNumberAffected = 'min-side-power-outage-number-affected',
  minSidePowerOutageOutsideArea = 'min-side-power-outage-outside-area',
  minSidePowerOutageOutsideAreaDescription = 'min-side-power-outage-outside-area-description',
  minSidePowerOutagePlannedFrom = 'min-side-power-outage-planned-from',
  minSidePowerOutagePlannedInfoOverdue = 'min-side-power-outage-planned-info-overdue',
  minSidePowerOutagePlannedInfoUpcomming = 'min-side-power-outage-planned-info-upcomming',
  minSidePowerOutagePlannedTo = 'min-side-power-outage-planned-to',
  minSidePowerOutageSearch = 'min-side-power-outage-search',
  minSidePowerOutageSelectedAreaPart1 = 'min-side-power-outage-selected-area-part-1',
  minSidePowerOutageSelectedAreaPart2 = 'min-side-power-outage-selected-area-part-2',
  minSidePowerOutageSelectedAreaPart3 = 'min-side-power-outage-selected-area-part-3',
  minSidePowerOutageSelectedMunicipalitiesPart = 'min-side-power-outage-selected-municipalities-part',
  minSidePowerOutageStatus = 'min-side-power-outage-status',
  minSidePowerOutageZoomIn = 'min-side-power-outage-zoom-in',
  minSidePowerOutageZoomMapControlDescription = 'min-side-power-outage-zoom-map-control-description',
  minSidePowerOutageZoomOut = 'min-side-power-outage-zoom-out',
  minSidePriceDistributionEmptyText = 'min-side-price-distribution-empty-text',
  minSidePriceDistributionEmptyTitle = 'min-side-price-distribution-empty-title',
  minSidePriceDistributionErrorText = 'min-side-price-distribution-error-text',
  minSidePriceEmptyStateText = 'min-side-price-empty-state-text',
  minSidePriceEmptyStateTitle = 'min-side-price-empty-state-title',
  minSidePriceTablesExVatNote = 'min-side-price-tables-ex-vat-note',
  minSideProductionSharingAnyQuestions = 'min-side-production-sharing-any-questions',
  minSideProductionSharingChangeContractInfo = 'min-side-production-sharing-change-contract-info',
  minSideProductionSharingChangeContractNotAllowed = 'min-side-production-sharing-change-contract-not-allowed',
  minSideProductionSharingChangeContractTitle = 'min-side-production-sharing-change-contract-title',
  minSideProductionSharingChangeContractUnderProcessBody = 'min-side-production-sharing-change-contract-under-process-body',
  minSideProductionSharingChangeContractUnderProcessTitle = 'min-side-production-sharing-change-contract-under-process-title',
  minSideProductionSharingContractEndDate = 'min-side-production-sharing-contract-end-date',
  minSideProductionSharingContractName = 'min-side-production-sharing-contract-name',
  minSideProductionSharingContractParticipants = 'min-side-production-sharing-contract-participants',
  minSideProductionSharingContractStartdate = 'min-side-production-sharing-contract-startdate',
  minSideProductionSharingContractStatus = 'min-side-production-sharing-contract-status',
  minSideProductionSharingContractStatusConfirmed = 'min-side-production-sharing-contract-status-confirmed',
  minSideProductionSharingContractStatusDeleted = 'min-side-production-sharing-contract-status-deleted',
  minSideProductionSharingContractStatusFailed = 'min-side-production-sharing-contract-status-failed',
  minSideProductionSharingContractStatusOrdered = 'min-side-production-sharing-contract-status-ordered',
  minSideProductionSharingContractStatusProcessing = 'min-side-production-sharing-contract-status-processing',
  minSideProductionSharingContractTableContributors = 'min-side-production-sharing-contract-table-contributors',
  minSideProductionSharingContractTableParticipants = 'min-side-production-sharing-contract-table-participants',
  minSideProductionSharingDeleteContractButton = 'min-side-production-sharing-delete-contract-button',
  minSideProductionSharingDeleteContractError = 'min-side-production-sharing-delete-contract-error',
  minSideProductionSharingDeleteContractInfo = 'min-side-production-sharing-delete-contract-info',
  minSideProductionSharingDeleteContractSuccessInfo = 'min-side-production-sharing-delete-contract-success-info',
  minSideProductionSharingDeleteContractSuccessTitle = 'min-side-production-sharing-delete-contract-success-title',
  minSideProductionSharingDeleteContractTitle = 'min-side-production-sharing-delete-contract-title',
  minSideProductionSharingEndDateError = 'min-side-production-sharing-end-date-error',
  minSideProductionSharingFormInvalidErrorTitle = 'min-side-production-sharing-form-invalid-error-title',
  minSideProductionSharingGoToSchemaButton = 'min-side-production-sharing-go-to-schema-button',
  minSideProductionSharingInfoOwner = 'min-side-production-sharing-info-owner',
  minSideProductionSharingLoadingEmpty = 'min-side-production-sharing-loading-empty',
  minSideProductionSharingLoadingError = 'min-side-production-sharing-loading-error',
  minSideProductionSharingOwnerMissingParticipants = 'min-side-production-sharing-owner-missing-participants',
  minSideProductionSharingOwnerReadMore = 'min-side-production-sharing-owner-read-more',
  minSideProductionSharingSchemaContractName = 'min-side-production-sharing-schema-contract-name',
  minSideProductionSharingSchemaContractNameMaxLengthError = 'min-side-production-sharing-schema-contract-name-max-length-error',
  minSideProductionSharingSchemaContractNamePlaceholder = 'min-side-production-sharing-schema-contract-name-placeholder',
  minSideProductionSharingSchemaContributorError = 'min-side-production-sharing-schema-contributor-error',
  minSideProductionSharingSchemaCreatedSuccessText = 'min-side-production-sharing-schema-created-success-text',
  minSideProductionSharingSchemaDistributionDecimalsError = 'min-side-production-sharing-schema-distribution-decimals-error',
  minSideProductionSharingSchemaDistributionError = 'min-side-production-sharing-schema-distribution-error',
  minSideProductionSharingSchemaDistributionTooHighError = 'min-side-production-sharing-schema-distribution-too-high-error',
  minSideProductionSharingSchemaDistributionTooLowError = 'min-side-production-sharing-schema-distribution-too-low-error',
  minSideProductionSharingSchemaEnddate = 'min-side-production-sharing-schema-enddate',
  minSideProductionSharingSchemaMeteringPoint = 'min-side-production-sharing-schema-metering-point',
  minSideProductionSharingSchemaMeteringPointsContributing = 'min-side-production-sharing-schema-metering-points-contributing',
  minSideProductionSharingSchemaMeteringPointsContributingInfo = 'min-side-production-sharing-schema-metering-points-contributing-info',
  minSideProductionSharingSchemaNoContractNameError = 'min-side-production-sharing-schema-no-contract-name-error',
  minSideProductionSharingSchemaNoParticipantsError = 'min-side-production-sharing-schema-no-participants-error',
  minSideProductionSharingSchemaParticipants = 'min-side-production-sharing-schema-participants',
  minSideProductionSharingSchemaParticipantsConsent = 'min-side-production-sharing-schema-participants-consent',
  minSideProductionSharingSchemaParticipantsConsentError = 'min-side-production-sharing-schema-participants-consent-error',
  minSideProductionSharingSchemaParticipantsDistribution = 'min-side-production-sharing-schema-participants-distribution',
  minSideProductionSharingSchemaParticipantsDistributionEqually = 'min-side-production-sharing-schema-participants-distribution-equally',
  minSideProductionSharingSchemaParticipantsDistributionManually = 'min-side-production-sharing-schema-participants-distribution-manually',
  minSideProductionSharingSchemaParticipantsError = 'min-side-production-sharing-schema-participants-error',
  minSideProductionSharingSchemaParticipantsInclude = 'min-side-production-sharing-schema-participants-include',
  minSideProductionSharingSchemaParticipantsMaxEffect = 'min-side-production-sharing-schema-participants-max-effect',
  minSideProductionSharingSchemaSendError = 'min-side-production-sharing-schema-send-error',
  minSideProductionSharingSchemaStartdate = 'min-side-production-sharing-schema-startdate',
  minSideProductionSharingSchemaSuccessTitle = 'min-side-production-sharing-schema-success-title',
  minSideProductionSharingSchemaTermsAccepted = 'min-side-production-sharing-schema-terms-accepted',
  minSideProductionSharingSchemaTermsAcceptedError = 'min-side-production-sharing-schema-terms-accepted-error',
  minSideProductionSharingSchemaYourMeteringPoint = 'min-side-production-sharing-schema-your-metering-point',
  minSideProductionSharingStartDateCannotChange = 'min-side-production-sharing-start-date-cannot-change',
  minSideProductionSharingStartDateCannotChangeLater = 'min-side-production-sharing-start-date-cannot-change-later',
  minSideProductionSharingStartDateError = 'min-side-production-sharing-start-date-error',
  minSideProductionSharingTabDescription = 'min-side-production-sharing-tab-description',
  minSideProductionSharingTableEmpty = 'min-side-production-sharing-table-empty',
  minSideProductionSharingTitle = 'min-side-production-sharing-title',
  minSideProductionSharingUpdateContractButton = 'min-side-production-sharing-update-contract-button',
  minSideProfileAccessAddNewCustomerHeader = 'min-side-profile-access-add-new-customer-header',
  minSideProfileAccessAddNewCustomerText = 'min-side-profile-access-add-new-customer-text',
  minSideProfileAccessCorporateCustomerLink = 'min-side-profile-access-corporate-customer-link',
  minSideProfileAccessDeleteTableHeader = 'min-side-profile-access-delete-table-header',
  minSideProfileAccessErrorBody = 'min-side-profile-access-error-body',
  minSideProfileAccessLastAccessedTableHeader = 'min-side-profile-access-last-accessed-table-header',
  minSideProfileAccessLastAccessedTableMissingValue = 'min-side-profile-access-last-accessed-table-missing-value',
  minSideProfileAccessPersonsWithAccessTableHeader = 'min-side-profile-access-persons-with-access-table-header',
  minSideProfileAccessPrivateCustomerLink = 'min-side-profile-access-private-customer-link',
  minSideProfileAccessRequestCorporateCustomernumberLabel = 'min-side-profile-access-request-corporate-customernumber-label',
  minSideProfileAccessRequestCorporateCustomernumberPlaceholder = 'min-side-profile-access-request-corporate-customernumber-placeholder',
  minSideProfileAccessRequestCorporateMeteringpointLabel = 'min-side-profile-access-request-corporate-meteringpoint-label',
  minSideProfileAccessRequestCorporateMeteringpointPlaceholder = 'min-side-profile-access-request-corporate-meteringpoint-placeholder',
  minSideProfileAccessRequestCorporateSubmitButton = 'min-side-profile-access-request-corporate-submit-button',
  minSideProfileAccessRequestLinkCorporate = 'min-side-profile-access-request-link-corporate',
  minSideProfileAccessRequestLinkPrivate = 'min-side-profile-access-request-link-private',
  minSideProfileAccessRoleTableHeader = 'min-side-profile-access-role-table-header',
  minSideProfileAccessTokenAccessMissingValue = 'min-side-profile-access-token-access-missing-value',
  minSideProfileAddEmailLink = 'min-side-profile-add-email-link',
  minSideProfileChangePasswordLink = 'min-side-profile-change-password-link',
  minSideProfileCustomerRelationshipErrorText = 'min-side-profile-customer-relationship-error-text',
  minSideProfileCustomerRelationshipErrorTitle = 'min-side-profile-customer-relationship-error-title',
  minSideProfileCustomerRelationshipTitle = 'min-side-profile-customer-relationship-title',
  minSideProfileCustomerScopeBedriftTitle = 'min-side-profile-customer-scope-bedrift-title',
  minSideProfileCustomerScopePrivatTitle = 'min-side-profile-customer-scope-privat-title',
  minSideProfileDeleteAccessDialogBodyText = 'min-side-profile-delete-access-dialog-body-text',
  minSideProfileDeleteAccessDialogConfirmBtn = 'min-side-profile-delete-access-dialog-confirm-btn',
  minSideProfileDeleteAccessDialogHeader = 'min-side-profile-delete-access-dialog-header',
  minSideProfileDeleteTokenAccessDialogBodyText = 'min-side-profile-delete-token-access-dialog-body-text',
  minSideProfileDeleteTokenAccessDialogConfirmBtn = 'min-side-profile-delete-token-access-dialog-confirm-btn',
  minSideProfileDeleteTokenAccessDialogHeader = 'min-side-profile-delete-token-access-dialog-header',
  minSideProfileManageLoginsLink = 'min-side-profile-manage-logins-link',
  minSideProfileManageTwoFactorLink = 'min-side-profile-manage-two-factor-link',
  minSideProfileMyUserLink = 'min-side-profile-my-user-link',
  minSideProfilePersonalInfoCustomerNumber = 'min-side-profile-personal-info-customer-number',
  minSideProfilePersonalInfoDescription = 'min-side-profile-personal-info-description',
  minSideProfilePersonalInfoDescriptionPowerSupplierAfter = 'min-side-profile-personal-info-description-power-supplier-after',
  minSideProfilePersonalInfoDescriptionPowerSupplierBefore = 'min-side-profile-personal-info-description-power-supplier-before',
  minSideProfilePersonalInfoDescriptionTitle = 'min-side-profile-personal-info-description-title',
  minSideProfilePersonalInfoEPost = 'min-side-profile-personal-info-e-post',
  minSideProfilePersonalInfoMobile = 'min-side-profile-personal-info-mobile',
  minSideProfilePersonalInfoName = 'min-side-profile-personal-info-name',
  minSideProfilePersonalInfoPowerSupplier = 'min-side-profile-personal-info-power-supplier',
  minSideProfilePersonalInfoPowerSupplierFootnote = 'min-side-profile-personal-info-power-supplier-footnote',
  minSideProfilePersonalInfoPowerSupplierMultipleContracts = 'min-side-profile-personal-info-power-supplier-multiple-contracts',
  minSideProfilePersonalInfoTitle = 'min-side-profile-personal-info-title',
  minSideProfileSignInTitle = 'min-side-profile-sign-in-title',
  minSideProfileTokenAccessLastAccessedTableMissingValue = 'min-side-profile-token-access-last-accessed-table-missing-value',
  minSideRegisterBankAccountEditButton = 'min-side-register-bank-account-edit-button',
  minSideRegisterBankAccountError = 'min-side-register-bank-account-error',
  minSideRegisterBankAccountInfoText = 'min-side-register-bank-account-info-text',
  minSideRegisterBankAccountInfoTitle = 'min-side-register-bank-account-info-title',
  minSideRegisterBankAccountInputLabel = 'min-side-register-bank-account-input-label',
  minSideRegisterBankAccountInputPlaceholder = 'min-side-register-bank-account-input-placeholder',
  minSideRegisterBankAccountMissingAccountNumber = 'min-side-register-bank-account-missing-account-number',
  minSideRegisterBankAccountNoAccessText = 'min-side-register-bank-account-no-access-text',
  minSideRegisterBankAccountNoChangesError = 'min-side-register-bank-account-no-changes-error',
  minSideRegisterBankAccountPopoverContent = 'min-side-register-bank-account-popover-content',
  minSideRegisterBankAccountPopoverTitle = 'min-side-register-bank-account-popover-title',
  minSideRegisterBankAccountSubmitButton = 'min-side-register-bank-account-submit-button',
  minSideRegisterBankAccountSubmitEditButton = 'min-side-register-bank-account-submit-edit-button',
  minSideRegisterBankAccountTitle = 'min-side-register-bank-account-title',
  minSideRegisterBankAccountToastEdited = 'min-side-register-bank-account-toast-edited',
  minSideRegisterBankAccountToastSubmitted = 'min-side-register-bank-account-toast-submitted',
  minSideRequestAccessBusinessDescription = 'min-side-request-access-business-description',
  minSideRequestAccessHeader = 'min-side-request-access-header',
  minSideRequestAccessPrivateDescription = 'min-side-request-access-private-description',
  minSideSelectContract = 'min-side-select-contract',
  minSideSelectContractHistorical = 'min-side-select-contract-historical',
  minSideSelectCustomerNoRelationship = 'min-side-select-customer-no-relationship',
  minSideSelectCustomerNoRelationshipDescription = 'min-side-select-customer-no-relationship-description',
  minSideSelectCustomerNoRelationshipHeader = 'min-side-select-customer-no-relationship-header',
  minSideSelectCustomerTitle = 'min-side-select-customer-title',
  minSideSelectCustomerWelcome = 'min-side-select-customer-welcome',
  minSideShowCalculatedMaxHoursGraphButton = 'min-side-show-calculated-max-hours-graph-button',
  minSideShowCompensationGraphButton = 'min-side-show-compensation-graph-button',
  minSideSpotPriceGraphPopoverContent = 'min-side-spot-price-graph-popover-content',
  minSideSpotPriceGraphPopoverTitle = 'min-side-spot-price-graph-popover-title',
  minSideSpotPriceGraphTitle = 'min-side-spot-price-graph-title',
  minSideStatus = 'min-side-status',
  minSideStep = 'min-side-step',
  minSideTabsAccess = 'min-side-tabs-access',
  minSideTabsHanPort = 'min-side-tabs-han-port',
  minSideTabsInvoice = 'min-side-tabs-invoice',
  minSideTabsMeteringPoints = 'min-side-tabs-metering-points',
  minSideTabsNotification = 'min-side-tabs-notification',
  minSideTabsNotificationEdit = 'min-side-tabs-notification-edit',
  minSideTabsNotificationNoPreferences = 'min-side-tabs-notification-no-preferences',
  minSideTabsNotificationSaveSettings = 'min-side-tabs-notification-save-settings',
  minSideTabsNotificationSubmitButton = 'min-side-tabs-notification-submit-button',
  minSideTabsNotificationSubmitError = 'min-side-tabs-notification-submit-error',
  minSideTabsNotificationTypeMeterReading = 'min-side-tabs-notification-type-meter-reading',
  minSideTabsNotificationTypeOtherNotifications = 'min-side-tabs-notification-type-other-notifications',
  minSideTabsNotificationTypePowerOutage = 'min-side-tabs-notification-type-power-outage',
  minSideTabsProductionSharing = 'min-side-tabs-production-sharing',
  minSideTariff = 'min-side-tariff',
  minSideTariffCurrentMaxHour = 'min-side-tariff-current-max-hour',
  minSideTariffDay = 'min-side-tariff-day',
  minSideTariffFixedLevel = 'min-side-tariff-fixed-level',
  minSideTariffInfoBoxCompensationAverageSpotPrice = 'min-side-tariff-info-box-compensation-average-spot-price',
  minSideTariffInfoBoxCompensationConsumption = 'min-side-tariff-info-box-compensation-consumption',
  minSideTariffInfoBoxCompensationFootnote = 'min-side-tariff-info-box-compensation-footnote',
  minSideTariffInfoBoxCompensationResult = 'min-side-tariff-info-box-compensation-result',
  minSideTariffInfoBoxCompensationText = 'min-side-tariff-info-box-compensation-text',
  minSideTariffInfoBoxCompensationTitle = 'min-side-tariff-info-box-compensation-title',
  minSideTariffInfoBoxNewCompensationDynamicText = 'min-side-tariff-info-box-new-compensation-dynamic-text',
  minSideTariffInfoBoxNewCompensationLink = 'min-side-tariff-info-box-new-compensation-link',
  minSideTariffInfoBoxNewCompensationTitle = 'min-side-tariff-info-box-new-compensation-title',
  minSideTariffInfoBoxPriceEmptyStateHistoricalContractContent = 'min-side-tariff-info-box-price-empty-state-historical-contract-content',
  minSideTariffInfoBoxPriceEmptyStateHistoricalContractTitle = 'min-side-tariff-info-box-price-empty-state-historical-contract-title',
  minSideTariffInfoBoxPriceErrorText = 'min-side-tariff-info-box-price-error-text',
  minSideTariffInfoBoxTabsCompensation = 'min-side-tariff-info-box-tabs-compensation',
  minSideTariffInfoBoxTabsInsight = 'min-side-tariff-info-box-tabs-insight',
  minSideTariffInfoBoxTabsNettleie = 'min-side-tariff-info-box-tabs-nettleie',
  minSideTariffMaxHour = 'min-side-tariff-max-hour',
  minSideTariffNightAndWeekend = 'min-side-tariff-night-and-weekend',
  minSideTariffPricesCorporate = 'min-side-tariff-prices-corporate',
  minSideTariffPricesEnergyPriceTitle = 'min-side-tariff-prices-energy-price-title',
  minSideTariffPricesFixedLevelTableColumn = 'min-side-tariff-prices-fixed-level-table-column',
  minSideTariffPricesFixedLevelTableHeader = 'min-side-tariff-prices-fixed-level-table-header',
  minSideTariffPricesPrivate = 'min-side-tariff-prices-private',
  minSideTariffPricesPrivateNewFootnoteDynamic = 'min-side-tariff-prices-private-new-footnote-dynamic',
  minSideTooltipDataEstimatedFootnote = 'min-side-tooltip-data-estimated-footnote',
  minSideTooltipDataUnverifiedFootnote = 'min-side-tooltip-data-unverified-footnote',
  minSideTooltipHour = 'min-side-tooltip-hour',
  minSideTotalCalculatedCostUnitOfMeasure = 'min-side-total-calculated-cost-unit-of-measure',
  minSideTotalConsumption = 'min-side-total-consumption',
  minSideTotalCostBasedOnSpotPrice = 'min-side-total-cost-based-on-spot-price',
  minSideTotalCostCompensation = 'min-side-total-cost-compensation',
  minSideTotalCostElvia = 'min-side-total-cost-elvia',
  minSideTotalCostEstimatedTotalCost = 'min-side-total-cost-estimated-total-cost',
  minSideTotalCostExternalPowerSupplier = 'min-side-total-cost-external-power-supplier',
  minSideTotalCostFootnote = 'min-side-total-cost-footnote',
  minSideTotalCostIsNotAms = 'min-side-total-cost-is-not-ams',
  minSideTotalCostMainTitle = 'min-side-total-cost-main-title',
  minSideTotalCostPowerCost = 'min-side-total-cost-power-cost',
  minSideTotalCostTariffCost = 'min-side-total-cost-tariff-cost',
  minSideTotalCostThisMonth = 'min-side-total-cost-this-month',
  minSideValidationBankAccount = 'min-side-validation-bank-account',
  minSideValidationEmail = 'min-side-validation-email',
  minSideValidationEmails = 'min-side-validation-emails',
  minSideValidationIsDate = 'min-side-validation-is-date',
  minSideValidationIsNumber = 'min-side-validation-is-number',
  minSideValidationMaxLength = 'min-side-validation-max-length',
  minSideValidationMinLength = 'min-side-validation-min-length',
  minSideValidationMobile = 'min-side-validation-mobile',
  minSideValidationMobiles = 'min-side-validation-mobiles',
  minSideValidationRequired = 'min-side-validation-required',
  minsideSomethingWentWrongBody = 'minside-something-went-wrong-body',
  minsideSomethingWentWrongLink = 'minside-something-went-wrong-link',
  minsideSomethingWentWrongTitle = 'minside-something-went-wrong-title',
  mobileNumber = 'mobile-number',
  month = 'month',
  next = 'next',
  no = 'no',
  noEnglishTranslationDescription = 'no-english-translation-description',
  noEnglishTranslationLink = 'no-english-translation-link',
  noPowerOutage = 'no-power-outage',
  notFound = 'not-found',
  openCustomerServiceChatLink = 'open-customer-service-chat-link',
  owner = 'owner',
  paginationMeteringpoints = 'pagination-meteringpoints',
  paginationOf = 'pagination-of',
  paginationShows = 'pagination-shows',
  powerOutage = 'power-outage',
  requiredField = 'required-field',
  searchButton = 'search-button',
  searchFieldPlaceholder = 'search-field-placeholder',
  searchResultMissing = 'search-result-missing',
  searchTitle = 'search-title',
  send = 'send',
  signOut = 'sign-out',
  submittingForm = 'submitting-form',
  tableMissingData = 'tableMissingData',
  testWatermark = 'test-watermark',
  testWatermarkForms = 'test-watermark-forms',
  token = 'token',
  week = 'week',
  year = 'year',
  yes = 'yes',
}
