import React from 'react'

export const replace = (
  target: string,
  search: string,
  replacement: (key: string) => React.ReactNode
): React.ReactNode => {
  if (typeof target !== 'string') return target

  return target
    .split(search)
    .map((substring, i, { length }) =>
      i !== length - 1 ? [substring, replacement(substring)] : substring
    )
}
