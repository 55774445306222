import React, { CSSProperties, FC } from 'react'
import * as css from './Spinner.module.scss'
import classnames from 'classnames'

export interface SpinnerProps {
  className?: string
  style?: CSSProperties
}

export const Spinner: FC<SpinnerProps> = ({ className, style }) => (
  <div className={classnames(className, css.spinner)} style={style}>
    <div className={css.bounce1}></div>
    <div className={css.bounce2}></div>
    <div></div>
  </div>
)
