import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { Document } from '@contentful/rich-text-types'
import camelcase from 'camelcase'
import { useStaticQuery, graphql } from 'gatsby'
import { memoizeWith, always } from 'ramda'

import { RenderRichText } from '@elvia/rich-text-render/src/richTextRender'
import { RenderRichTextOptions } from '@elvia/terms/src/renderRichTextTypes'
import {
  DynamicTerms,
  RichTerms,
  Terms,
  TermsEnum,
} from '@elvia/terms/src/Terms'

import { InitialTermsProps } from '@elvia/terms/src/useTerms'

export interface ElviaTerm {
  id: string
  termId: string
  locale: string
  body: { nb: Document }
  dynamic?: { nb: boolean }
}

export interface GetAllTermsResponseData {
  allElviaTerm: {
    nodes: ElviaTerm[]
  }
}

export const useTerms: () => InitialTermsProps = memoizeWith(
  always('all'),
  () => {
    const data: GetAllTermsResponseData = useStaticQuery(graphql`
      query GetAllTerms {
        allElviaTerm {
          nodes {
            termId
            body
            locale
            dynamic {
              nb
            }
            id
          }
        }
      }
    `)

    const terms = {
      nb: data.allElviaTerm.nodes.reduce<Terms>(
        (acc, item) => ({
          ...acc,
          [camelcase(item.termId)]: documentToPlainTextString(item.body.nb),
        }),
        Object.keys(TermsEnum).reduce(
          (acc, item) => ({ ...acc, [item]: item }),
          {} as Terms
        )
      ),
    }

    const dynamicTerms = {
      nb: data.allElviaTerm.nodes
        .filter(node => node.dynamic)
        .reduce(
          (acc, item) => ({
            ...acc,
            [camelcase(item.termId)]: (options: string[]) => {
              let termAsString = documentToPlainTextString(item.body.nb)

              options.forEach((str, index) => {
                if (termAsString.includes('$' + index)) {
                  termAsString = termAsString.replace('$' + index, str)
                }
              })

              return termAsString
            },
          }),
          Object.keys(TermsEnum).reduce(
            (acc, item) => ({ ...acc, [item]: () => item }),
            {} as DynamicTerms
          )
        ),
    }

    const richTerms = {
      nb: data.allElviaTerm.nodes.reduce(
        (acc, item) => ({
          ...acc,
          [camelcase(item.termId)]: (options: RenderRichTextOptions) =>
            RenderRichText(item.body.nb, options),
        }),
        Object.keys(TermsEnum).reduce(
          (acc, item) => ({ ...acc, [item]: () => item }),
          {} as RichTerms
        )
      ),
    }
    return { terms, richTerms, dynamicTerms }
  }
)
