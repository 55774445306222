import classnames from 'classnames'
import React, {
  KeyboardEvent,
  FC,
  forwardRef,
  ReactNode,
  CSSProperties,
  useState,
} from 'react'

import { Link, LinkType } from '../Link'
import * as css from './Menu.module.scss'
import { ElvisIcon } from '../ElvisIcon'

export interface MenuItemProps {
  url: string
  onClick?: () => void
  minSide?: boolean
  onKeyDown?: (e: KeyboardEvent<HTMLAnchorElement>) => void
  onKeyUp?: (e: KeyboardEvent<HTMLAnchorElement>) => void
  children?: ReactNode
  className?: string
}
export const MenuItem = forwardRef<HTMLAnchorElement, MenuItemProps>(
  (props, ref) => {
    const {
      url,
      children,
      onClick,
      minSide = false,
      onKeyDown,
      onKeyUp,
      className,
    } = props

    return (
      <div
        className={classnames(css.menuListItem, className)}
        onClick={onClick}
      >
        <Link
          ref={ref}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          className={classnames(css.menuListLink, {
            [css.menuListLinkMinSide]: minSide,
          })}
          href={url}
          type={LinkType.NoDecoration}
        >
          {children}
          {minSide && <ElvisIcon iconName="arrowRightBold" fontSize={12} />}
        </Link>
      </div>
    )
  }
)

MenuItem.displayName = 'MenuItem'

export interface MenuProps {
  title: string
  children?: ReactNode
  asDropdownMenu?: boolean
  className?: string
  style?: CSSProperties
}

export const Menu: FC<MenuProps> = ({
  title,
  children,
  asDropdownMenu = false,
  className,
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const classes = classnames(css.menu, { [css.menuShow]: showMenu }, className)
  const MenuItems = (
    <div data-testid="Menu" className={classes}>
      <div className={css.menuList}>{children}</div>
    </div>
  )

  return asDropdownMenu ? (
    <div data-testid="DropdownMenu" className={css.dropdownMenu}>
      <div className={css.menuTitle}>
        <button
          onClick={event => {
            event.preventDefault()
            setShowMenu(!showMenu)
          }}
          className={classnames(css.menuItem, {
            [css.menuItemActive]: showMenu,
          })}
        >
          {title}
          {showMenu ? (
            <ElvisIcon iconName={'arrowUpBold'} fontSize={12} />
          ) : (
            <ElvisIcon iconName={'arrowDownBold'} fontSize={12} />
          )}
        </button>
      </div>
      {showMenu && MenuItems}
    </div>
  ) : (
    MenuItems
  )
}
