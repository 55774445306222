import classnames from 'classnames'
import { all, pipe, path, isNil, not, allPass, any } from 'ramda'
import React, { FC } from 'react'

import { useTerms } from '@elvia/terms'
import { ElvisIcon } from '@elvia/ui/src/components/ElvisIcon'
import { Image, ImageProps } from '@elvia/ui/src/components/Image'
import { RelatedContent } from '@elvia/ui/src/components/RelatedContent'
import { IconName } from '@elvia/ui/src/components/ElvisIcon/ElvisIcon'
import * as css from './RelatedContentWrapper.module.scss'
import { useLocalization } from '@elvia/localization/src/useLocalization'

export type Id = string
export type Title = string
export type Preamble = string
export type Body = Document
export type MetaTitle = string
export type MetaDescription = string
export type TypeName = string
export type Url = { nb: string; [key: string]: string }
export type ReadTime = number
export type UpdatedAt = string

interface SectionImageProps extends Omit<ImageProps, 'alt'> {
  alt: { nb: string; [key: string]: string }
}

interface SectionImage extends Omit<Image, 'imgProps'> {
  imgProps: SectionImageProps
}

export interface Section {
  id: Id
  title: { nb: string; [key: string]: string }
  typeName: TypeName
  url: Url
  preamble: { nb: string; [key: string]: string }
  image?: SectionImage
  icon?: IconName
  linkText?: { nb: string; [key: string]: string }
}

export type Sections = Section[]

interface RelatedContentWrapperProps {
  relatedContent: Sections
  locale?: string
  roundImage?: boolean
  largeViewType?: boolean
  centerText?: boolean
  showIcons?: boolean
  className?: string
}

export const RelatedContentWrapper: FC<RelatedContentWrapperProps> = ({
  relatedContent,
  largeViewType = false,
  roundImage,
  centerText,
  showIcons,
  className,
}) => {
  const { terms } = useTerms()
  const { getLocalizedValue } = useLocalization()
  if (!relatedContent) return null

  type PathIsNil = (path: string[]) => (data: unknown) => boolean
  const pathIsNil: PathIsNil = pathToCheck => pipe(path(pathToCheck), isNil)
  const pathIsNotNil: PathIsNil = pathToCheck =>
    pipe(pathIsNil(pathToCheck), not)

  const isMissingImage = allPass([
    pathIsNil(['image', 'fluid']),
    pathIsNil(['image', 'src']),
  ])

  const hasSingleRoundImage = relatedContent.length === 1 && roundImage

  const isArrayMissingAnyImages = any(isMissingImage)

  const showImages = !showIcons && !isArrayMissingAnyImages(relatedContent)
  const hasIcon = pathIsNotNil(['icon'])

  const displayIcons = !!showIcons && all(hasIcon)(relatedContent)
  if (relatedContent.every(x => !getLocalizedValue(x.url, true))) return null

  return (
    <div className={classnames(className, css.relatedContentWrapper)}>
      {relatedContent.map((content: Section, index: number) => {
        if (!getLocalizedValue(content.url, true)) return null
        return (
          <RelatedContent
            key={getLocalizedValue(content.title) + index}
            multiContent={relatedContent.length > 1}
            className={classnames(
              hasSingleRoundImage ? css.relatedContentWrapperSingleRound : '',
              relatedContent.length === 2 ? css.relatedContentWrapperMulti : ''
            )}
            centerText={centerText}
            largeViewType={largeViewType}
            title={getLocalizedValue(content.title)}
            preamble={getLocalizedValue(content.preamble)}
            url={getLocalizedValue(content.url)}
            linkText={
              (content.linkText && getLocalizedValue(content.linkText)) ||
              terms.defaultLinkTextRelatedContent
            }
            imageComponent={
              showImages &&
              content.image?.srcSet && (
                <Image
                  {...content.image.imgProps}
                  sizes="(max-width: 668px) 33.33vw, 668px"
                  className={
                    hasSingleRoundImage
                      ? css.relatedContentWrapperSingleRoundImage
                      : ''
                  }
                  alt={getLocalizedValue(content.image.imgProps.alt)}
                  focalPoint={content.image.focalPoint}
                  round={roundImage}
                />
              )
            }
            icon={
              displayIcons &&
              content.icon && <ElvisIcon iconName={content.icon} />
            }
          />
        )
      })}
    </div>
  )
}
