import React, { FC, useMemo, useState } from 'react'
import classnames from 'classnames'
import { useTerms } from '@elvia/terms'
import { Typography, TypographyType } from '@elvia/ui/src/components/Typography'
import { ContentLoaderWithStates } from '@elvia/ui/src/components/ContentLoaderWithStates'
import { Input } from '@elvia/ui/src/components/Input'
import {
  Dropdown,
  DropdownItem,
} from '@elvia/ui/src/components/Dropdown/Dropdown'

import { NoResultsOnWhiteColor, WentWrongOnWhiteColor } from '@elvia/icons'

import * as css from './Jobylon.module.scss'
import { useJobylonApi } from './useJobylonApi'
import JobylonCard from './JobylonCard'

interface Props {
  jobylonUrl: string
}

const JobylonContainer: FC<Props> = ({ jobylonUrl }) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedPlace, setSelectedPlace] = useState<string[]>([])
  const [selectedCategory, setSelectedCategory] = useState<string[]>([])
  const { terms } = useTerms()
  const { data: jobListings, status } = useJobylonApi(jobylonUrl)

  const places: DropdownItem[] = useMemo(() => {
    if (!jobListings) return []
    return jobListings
      .flatMap(entry =>
        entry.locations.map(x => {
          return {
            value: x.location.place_id,
            label: x.location.city,
          }
        })
      )
      .reduce((acc, curr) => {
        if (!acc.find(x => x.value === curr.value)) {
          return [...acc, curr]
        }
        return acc
      }, [] as DropdownItem[])
      .filter(x => !!x)
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [jobListings])

  const categories: DropdownItem[] = useMemo(() => {
    if (!jobListings) return []
    return jobListings
      .flatMap(entry =>
        entry.layers_3.map(x => ({
          value: x.layer.identifier,
          label: x.layer.text,
        }))
      )
      .reduce((acc, curr) => {
        if (!acc.find(x => x.value === curr.value)) {
          return [...acc, curr]
        }
        return acc
      }, [] as DropdownItem[])
      .filter(x => !!x)
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [jobListings])

  const filteredJobListings = useMemo(() => {
    if (!jobListings) return []
    return jobListings.filter(entry => {
      let searchTermMatch = true
      let placeMatch = true
      let categoryMatch = true
      if (searchTerm) {
        searchTermMatch =
          entry.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          entry.descr.toLowerCase().includes(searchTerm.toLowerCase())
      }
      if (selectedPlace.length > 0) {
        placeMatch = false
        entry.locations.forEach(location => {
          if (selectedPlace.includes(location.location.place_id)) {
            placeMatch = true
          }
        })
      }
      if (selectedCategory.length > 0) {
        categoryMatch = false
        entry.layers_3.forEach(layer => {
          if (selectedCategory.includes(layer.layer.identifier)) {
            categoryMatch = true
          }
        })
      }

      return searchTermMatch && placeMatch && categoryMatch
    })
  }, [jobListings, searchTerm, selectedPlace, selectedCategory])

  const dataState = useMemo(() => {
    if (jobListings?.length === 0 && status === 'success') return 'empty'
    switch (status) {
      case 'success':
        return 'success'
      case 'error':
        return 'rejected'
      case 'pending':
        return 'pending'
    }
  }, [status, jobListings])

  return (
    <ContentLoaderWithStates
      status={dataState}
      className={classnames(
        css.jobylonContainer,
        dataState === 'pending' && css.pending
      )}
      renderEmpty={
        <div className={css.information}>
          <NoResultsOnWhiteColor />
          <Typography type={TypographyType.TEXT_LARGE}>
            {terms.jobylonNoListings}
          </Typography>
        </div>
      }
      renderError={
        <div className={css.information}>
          <WentWrongOnWhiteColor />
          <Typography type={TypographyType.TEXT_LARGE}>
            {terms.jobylonError}
          </Typography>
        </div>
      }
    >
      <Input
        className={css.search}
        type="search"
        value={searchTerm}
        placeholder={terms.jobylonSearchPlaceholder}
        fullWidth
        onClear={() => setSearchTerm('')}
        onChange={e => setSearchTerm((e.target as HTMLInputElement).value)}
      />
      <div className={css.filters}>
        <Dropdown
          items={places}
          placeholder={terms.jobylonFilterPlace}
          value={selectedPlace}
          isMulti
          valueOnChange={value => {
            setSelectedPlace(value as string[])
          }}
        />
        <Dropdown
          items={categories}
          placeholder={terms.jobylonFilterPositionFunction}
          isMulti
          value={selectedCategory}
          valueOnChange={value => {
            setSelectedCategory(value as string[])
          }}
        />
      </div>
      <div className={css.overview}>
        <Typography type={TypographyType.TEXT_SMALL}>
          <b>{jobListings?.length}</b> {terms.jobylonAvailableJobs}
        </Typography>
      </div>
      <div className={css.jobylonList}>
        {filteredJobListings.length > 0 &&
          filteredJobListings.map(entry => (
            <JobylonCard entry={entry} key={entry.id} />
          ))}
        {filteredJobListings.length === 0 && (
          <div className={css.information}>
            <NoResultsOnWhiteColor />
            <Typography type={TypographyType.TEXT_LARGE}>
              {terms.jobylonSearchNoResult}
            </Typography>
          </div>
        )}
      </div>
    </ContentLoaderWithStates>
  )
}

export default JobylonContainer
