import * as React from 'react'
import * as css from './LinkImageBlock.module.scss'
import classnames from 'classnames'
import { TitleSmall, TextBody, TextDescription } from '../Typography'
import { ElvisIcon } from '../ElvisIcon'

export interface LinkImageBlockProps {
  smallTitle: string
  title: string
  src: string
  className?: string
  type?: LinkImageBlockType
  style?: React.CSSProperties
}

export enum LinkImageBlockType {
  STANDARD = 'standard',
  SIDEBAR = 'sidebar',
  CENTERED = 'centered',
}

export type LinkImageBlockTypes = keyof typeof LinkImageBlockType

export const LinkImageBlock: React.FC<LinkImageBlockProps> = ({
  smallTitle,
  title,
  src,
  className,
  type = LinkImageBlockType.STANDARD,
  style,
}) => {
  const classes = classnames({
    [css.linkimageblock]: type === LinkImageBlockType.STANDARD,
    [css.linkimageblockSidebar]: type === LinkImageBlockType.SIDEBAR,
    [css.linkimageblockCentered]: type === LinkImageBlockType.CENTERED,
  })

  return (
    <div
      data-testid="LinkImageBlock"
      className={classnames(classes, className)}
      style={style}
    >
      {type === LinkImageBlockType.SIDEBAR ? (
        <div className={css.titleMedium} data-testid="arrowLongRightBold">
          <ElvisIcon iconName="arrowLongRightBold" fontSize={24} />
          <span>{title}</span>
        </div>
      ) : (
        <>
          <img className={classnames(css.imageWrapper)} src={src} />
          <div
            className={classnames({
              [css.textWrapper]: type === LinkImageBlockType.STANDARD,
              [css.textWrapperGradient]: type === LinkImageBlockType.CENTERED,
            })}
          >
            {type === LinkImageBlockType.STANDARD && (
              <>
                <TitleSmall className={css.titleMedium}>{title}</TitleSmall>
                <TextDescription className={css.textBody}>
                  {smallTitle}
                </TextDescription>
              </>
            )}

            {type === LinkImageBlockType.CENTERED && (
              <>
                <TextBody className={css.textBody}>{smallTitle}</TextBody>
                <TitleSmall className={css.titleSmall}>{title}</TitleSmall>
                <div className={css.line} />
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}
