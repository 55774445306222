import React, { forwardRef } from 'react'
import { Button, ButtonProps } from './Button'
import { Tooltip } from '@elvia/elvis-tooltip/react'

import * as css from './Button.module.scss'

type ButtonWithTooltipProps = ButtonProps & {
  disabledInfo?: string
}

export const ButtonWithTooltip = forwardRef<
  HTMLButtonElement,
  ButtonWithTooltipProps
>((props, ref) => {
  const { disabledInfo, ...rest } = props
  return (
    <Tooltip
      content={disabledInfo ?? 'Knapp'}
      trigger={<Button {...rest} ref={ref} aria-disabled={props.disabled} />}
      showDelay={100}
      isDisabled={!props.disabled}
      className={css.buttonWithTooltip}
    />
  )
})

ButtonWithTooltip.displayName = 'ButtonWithTooltip'
