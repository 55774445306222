import * as React from 'react'
import classnames from 'classnames'

export interface ListProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  ordered?: boolean
  strong?: boolean
  icons?: boolean
}

export const List: React.FC<ListProps> = ({
  className,
  style,
  children,
  ordered,
  strong,
  icons,
}) => {
  const classes = classnames(
    'e-list',
    {
      'e-list--strong': strong,
      'e-list--numbers': ordered,
      'e-list--icons': icons,
    },
    className
  )
  const Tag = ordered ? 'ol' : 'ul'

  return (
    <Tag data-testid="List" className={classes} style={style}>
      {children}
    </Tag>
  )
}
