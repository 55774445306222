import dayjs, { Dayjs } from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/nb'
import { ReactNode } from 'react'

dayjs.extend(localeData)
dayjs.extend(updateLocale)

type FormatDate = (date?: Date | string) => ReactNode
export const formatDate: FormatDate = date => {
  if (!date) {
    return ''
  }

  if (
    date instanceof Date ||
    typeof date === 'string' ||
    typeof date === 'number'
  ) {
    return dayjs(date).format('HH:mm, DD. MMM')
  }

  throw new Error(
    `Invalid date object provided to the formatDate function. Received: ${date}`
  )
}

export const formatDayTitle = (
  date: string | Date | Dayjs,
  locale?: string
): string => {
  if (locale) {
    dayjs.locale('nb')
  }

  const d = dayjs(date)
  return d.format('D. MMMM YYYY')
}
