import classnames from 'classnames'
import * as React from 'react'

export interface LabelProps {
  children: React.ReactNode
  type?: LabelType
  active?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent) => void
  className?: string
  style?: React.CSSProperties
  tabIndex?: number
}

export enum LabelType {
  Default = 'Default',
  Info = 'Info',
  Safe = 'Safe',
  Warning = 'Warning',
  Dangerous = 'Dangerous',
}

export const Label: React.FC<LabelProps> = ({
  children,
  type = LabelType.Default,
  disabled,
  onClick,
  className,
  style,
  tabIndex = -1,
}) => {
  const classes = classnames(
    'e-tag',
    { 'e-tag--blue-berry': type === LabelType.Info },
    { 'e-tag--green': type === LabelType.Safe },
    { 'e-tag--orange': type === LabelType.Warning },
    { 'e-tag--red': type === LabelType.Dangerous },
    className
  )

  return (
    <div
      data-testid="Label"
      tabIndex={tabIndex}
      onClick={e => !disabled && (onClick ? onClick(e) : null)}
      style={style}
      className={classes}
    >
      {children}
    </div>
  )
}
