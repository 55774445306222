import classnames from 'classnames'
import React, { CSSProperties, FC } from 'react'

import { ElvisIcon } from '../ElvisIcon'
import * as css from './Download.module.scss'

export interface DownloadProps {
  className?: string
  style?: CSSProperties
  url: string
  text: string
}

export const Download: FC<DownloadProps> = ({
  className,
  style,
  url,
  text,
}) => {
  const classes = classnames(css.download, className)

  return (
    <div data-testid="Download" className={classes} style={style}>
      <a href={url}>
        <ElvisIcon iconName="download" />
        <span>{text}</span>
      </a>
    </div>
  )
}
