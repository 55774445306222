import React, { FC, ReactNode, CSSProperties } from 'react'
import * as css from './InfoBox.module.scss'
import classnames from 'classnames'
import { TitleMedium } from '../Typography'

export interface InfoBoxProps {
  icon?: ReactNode
  className?: string
  style?: CSSProperties
  content: string[] | ReactNode
  type?: string
  title?: string
}

export enum InfoBoxType {
  Info = 'Info',
  Default = 'Default',
  Form = 'Form',
  MinSide = 'MinSide',
}

export const InfoBox: FC<InfoBoxProps> = ({
  icon,
  content,
  type,
  title,
  className,
  style,
}) => {
  const classes = classnames(className, css.infobox, {
    [css.infoboxBlue]: type === InfoBoxType.Info,
    [css.infoboxGrey]: type === InfoBoxType.Form,
    [css.infoboxMinSide]: type === InfoBoxType.MinSide,
  })

  return (
    <div data-testid="InfoBox" className={classes} style={style}>
      {icon && <div className={css.iconWrapper}>{icon}</div>}
      {title && <TitleMedium>{title}</TitleMedium>}
      {content}
    </div>
  )
}
