import classnames from 'classnames'
import React, { ReactNode, CSSProperties, FC } from 'react'

import * as Svg from '@elvia/icons'

import { MediaTypes, useMediaOnly } from '../../hooks/useMedia/useMedia'
import { Image } from '../Image'
import { Link, LinkType } from '../Link'
import { Typography, TypographyTag, TypographyType } from '../Typography'
import * as css from './InfoCard.module.scss'

export interface InfoCardProps {
  body?: ReactNode
  className?: string
  icon?: string
  image?: Image
  largeViewType?: boolean
  linkText?: string
  style?: CSSProperties
  title: string
  url?: string
}

export const InfoCard: FC<InfoCardProps> = ({
  body,
  className,
  icon,
  image,
  linkText,
  style,
  title,
  url,
}) => {
  const classes = classnames(css.infoCard, className)
  const isMobile = useMediaOnly(MediaTypes.MOBILE)

  return (
    <div data-testid="InfoCard" className={classes} style={style}>
      {image
        ? image && (
            <div className={css.imageContainer}>
              <Image
                {...image.imgProps}
                className={css.iconAndImageContainer}
                focalPoint={image.focalPoint}
                round
              />
            </div>
          )
        : icon && (
            <div className={css.iconAndImageContainer}>
              {(
                (Svg as Record<string, () => ReactNode>)[
                  icon
                ] as () => ReactNode
              )()}
            </div>
          )}
      <div className={css.textContainer}>
        <Typography
          className={css.title}
          tag={TypographyTag.H2}
          centered={isMobile ? true : false}
          type={TypographyType.TITLE_SMALL}
        >
          {title}
        </Typography>
        {body}
        {linkText && url && (
          <Link className={css.actionLink} type={LinkType.Action} href={url}>
            {linkText}
          </Link>
        )}
      </div>
    </div>
  )
}
