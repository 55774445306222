// extracted by mini-css-extract-plugin
export var dropdownMenu = "Menu-module--dropdownMenu--4b28a";
export var menu = "Menu-module--menu--10060";
export var menuCloseBtn = "Menu-module--menu-close-btn--a4f75";
export var menuItem = "Menu-module--menuItem--5b2c9";
export var menuItemActive = "Menu-module--menuItemActive--8f7b0";
export var menuList = "Menu-module--menu-list--75c31";
export var menuListItem = "Menu-module--menu-list-item--bcf00";
export var menuListLink = "Menu-module--menuListLink--09c0f";
export var menuListLinkMinSide = "Menu-module--menuListLinkMinSide--e84a8";
export var menuShow = "Menu-module--menu-show--4e1bb";
export var menuTitle = "Menu-module--menuTitle--55b2b";