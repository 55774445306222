import classnames from 'classnames'
import React, { CSSProperties, FC, ReactNode } from 'react'

import * as css from './ContentLoader.module.scss'

export type ContentLoaderBackgroundColor = 'white' | 'grey' | 'dark'

type AsyncStatus =
  | 'idle'
  | 'pending'
  | 'fulfilled'
  | 'rejected'
  | 'empty'
  | 'error'
  | 'success'
  | 'loading'
  | 'fetching'
  | 'paused'

export interface ContentLoaderProps {
  children: ReactNode
  status: AsyncStatus
  className?: string
  style?: CSSProperties
  isLoadingClass?: string
  isErrorClass?: string
  renderError?: ReactNode
  renderEmpty?: ReactNode
  backgroundColor?: ContentLoaderBackgroundColor
}

export const ContentLoader: FC<ContentLoaderProps> = ({
  children,
  className,
  style,
  status = 'idle',
  isLoadingClass = '',
  isErrorClass = '',
  renderError,
  renderEmpty,
  backgroundColor,
}) => {
  const statusLoading = status === 'pending'
  const statusError = status === 'rejected'
  const statusEmpty = status === 'empty'

  const classes = classnames(
    css.contentLoader,
    {
      [css.isLoading]: statusLoading,
      [css.greyBackground]: backgroundColor === 'grey' && statusLoading,
      [css.darkBackground]: backgroundColor === 'dark' && statusLoading,
      [isLoadingClass]: statusLoading,
      [css.isError]: statusError,
      [isErrorClass]: statusError,
      [css.displayFallback]: statusError && !renderError,
    },
    className
  )

  return (
    <div data-testid="ContentLoader" className={classes} style={style}>
      <div className={classnames(statusLoading && css.loadingChildren)}>
        {statusEmpty && renderEmpty}
        {statusError && renderError}
        {!statusEmpty && !statusError && !statusLoading && children}
      </div>
    </div>
  )
}
