import { throttle } from 'lodash'
import { useState } from 'react'

import { useIsomorphicLayoutEffect } from '../useIsomorphicLayoutEffect'
import { breakpoints } from '../../theme'

const getWidth = () => (typeof window !== 'undefined' ? window.innerWidth : 0)

export enum MediaTypes {
  MOBILE,
  TABLET,
  DESKTOP,
}

const media = new Map<MediaTypes, number>()

media.set(MediaTypes.MOBILE, breakpoints.mobile)
media.set(MediaTypes.TABLET, breakpoints.tablet)
media.set(MediaTypes.DESKTOP, breakpoints.desktop)

export type UseMedia = (mediaType: MediaTypes) => boolean

export const useMedia: UseMedia = mediaType => {
  const [width, setWidth] = useState(0)
  useIsomorphicLayoutEffect(() => {
    const setDeviceWidth = () => setWidth(getWidth())
    setDeviceWidth()

    const throttledResize = throttle(setDeviceWidth, 250)
    window.addEventListener('resize', throttledResize)

    return () => {
      window.removeEventListener('resize', throttledResize)
    }
  }, [])

  return width >= (media.get(mediaType) || 0)
}

export type UseMediaOnly = (mediaType: MediaTypes) => boolean

export const useMediaOnly: UseMediaOnly = mediaType => {
  const [width, setWidth] = useState(0)
  useIsomorphicLayoutEffect(() => {
    const setDeviceWidth = () => setWidth(getWidth())
    setDeviceWidth()

    const throttledResize = throttle(setDeviceWidth, 250)

    window.addEventListener('resize', throttledResize)

    return () => {
      window.removeEventListener('resize', throttledResize)
    }
  }, [])

  const queryValue = media.get(mediaType) || 0
  let nextBreakpoint = 0

  media.forEach(value => {
    if (queryValue < value && nextBreakpoint === 0) {
      nextBreakpoint = value
    }
  })

  return width >= queryValue && width < nextBreakpoint
}
