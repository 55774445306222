import { navigate } from '@reach/router'
import React, { FC, useEffect } from 'react'

import { useAuth } from '@elvia/elvid-provider'
import { useTerms } from '@elvia/terms'
import { Button } from '@elvia/ui/src/components/Button'
import { ElvisIcon } from '@elvia/ui/src/components/ElvisIcon'
import { TitleMedium } from '@elvia/ui/src/components/Typography'
import { Document } from '@contentful/rich-text-types'

import * as css from './Login.module.scss'

export interface ElviaLogin {
  id: string
  title: string
  preamble: string
  content: Document
}

export const Login: FC = () => {
  const { isAuthenticated, signinRedirect } = useAuth()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/minside')
    }
  }, [isAuthenticated])

  const handleLogin = () => {
    signinRedirect()
  }

  const { terms, richTerms } = useTerms()

  return (
    <div className={css.container}>
      <div className={css.segmentContainer}>
        <div className={css.iconWrapper}>
          <ElvisIcon iconName="graphBarColor" fontSize={48} />
        </div>

        <TitleMedium className={css.titleMedium}>
          {terms.loginTitle}
        </TitleMedium>
        <Button size="large" fullWidth onClick={() => handleLogin()}>
          {terms.loginButton}
        </Button>
      </div>
      <div className={css.content}>{richTerms.loginPageBodyText()}</div>
    </div>
  )
}
