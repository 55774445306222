import React, { ReactNode } from 'react'

import { ElvidProvider } from '@elvia/elvid-provider'
import { ApiProvider } from '@elvia/kundeportal-api'
import LocalizationProviders from './LocalizationProviders'
import { CustomerParamsProvider } from '@elvia/minside-routing'

interface ProvidersProps {
  children?: ReactNode
}

export const Providers: React.FC<ProvidersProps> = ({ children }) => {
  return (
    <LocalizationProviders locale="nb">
      <ElvidProvider>
        <CustomerParamsProvider>
          <ApiProvider>{children}</ApiProvider>
        </CustomerParamsProvider>
      </ElvidProvider>
    </LocalizationProviders>
  )
}
