import queryString from 'query-string'
import React, { FC, useEffect } from 'react'

import { useTerms } from '@elvia/terms'
import { Button, ButtonType } from '@elvia/ui'

export const SalesforceLinkButton: FC = () => {
  const { terms } = useTerms()

  const openSalesforce = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { embeddedservice_bootstrap } = window as any

    if (embeddedservice_bootstrap && embeddedservice_bootstrap.utilAPI) {
      try {
        embeddedservice_bootstrap.utilAPI.launchChat()
      } catch (e) {
        console.error('could not start chat') //eslint-disable-line
      }
    }
  }

  useEffect(() => {
    const parsed = queryString.parse(location.search)
    const { chat } = parsed
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { embeddedservice_bootstrap } = window as any
    if (chat === 'open') {
      embeddedservice_bootstrap.addEventHandler(
        'onSettingsCallCompleted',
        () => {
          openSalesforce()
        }
      )
    }
  }, [])

  return (
    <Button onClick={openSalesforce} asType={ButtonType.InlineLink}>
      {terms.openCustomerServiceChatLink}
    </Button>
  )
}
