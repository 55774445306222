import classNames from 'classnames'
import React, { HTMLProps, forwardRef, useId } from 'react'
import { ElvisIcon } from '../ElvisIcon'

interface Props extends HTMLProps<HTMLInputElement> {
  label?: string
  optional?: boolean
  error?: string
  fullWidth?: boolean
  loading?: boolean
  inline?: boolean
  inputType?: string
  onClear?: () => void
  postfix?: React.ReactNode
}

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      error,
      fullWidth = false,
      optional = false,
      loading = false,
      className,
      required = false,
      inline = false,
      onClear = () => {},
      ...props
    },
    ref
  ) => {
    const id = useId()

    return (
      <div
        className={classNames(
          'e-form-field',
          fullWidth && 'e-form-field--full-width',
          inline && 'e-inlined-field',
          className
        )}
      >
        {label && (
          <label
            htmlFor={id}
            className={classNames(
              'e-form-field__label',
              optional && 'e-form-field__label--optional'
            )}
          >
            {label}
          </label>
        )}

        <div
          className={classNames(
            'e-input',
            error && 'e-input---invalid',
            loading && 'e-input---loading',
            props.disabled && 'e-input---disabled',
            !required && 'e-form-field__label--optional',
            props.readOnly && 'e-input---readonly'
          )}
        >
          {props.type === 'search' && (
            <ElvisIcon
              className="e-icon e-icon--search-color"
              aria-hidden="true"
              iconName="searchColor"
              fontSize={24}
            />
          )}

          <input
            aria-errormessage={error}
            aria-invalid={!!error}
            id={id}
            ref={ref}
            required={required}
            {...props}
          />

          {props.type === 'search' && (
            <button
              className={classNames(
                'e-btn e-btn--icon',
                !props.value && 'e-invisible'
              )}
              onClick={onClear}
              type="button"
              aria-label="Tøm søkefelt"
            >
              <span className="e-btn__icon">
                <ElvisIcon
                  className="e-icon--close"
                  aria-hidden="true"
                  iconName="close"
                  fontSize={16}
                />
              </span>
            </button>
          )}

          {props.postfix && props.type !== 'search' && (
            <span className={'e-mr-16'}>{props.postfix}</span>
          )}
        </div>

        <span
          className={classNames(
            'e-form-field__error ',
            error && 'e-form-field__error--visible'
          )}
        >
          <span>
            <i
              className="e-icon e-icon--remove_circle e-icon--color-error e-icon--xs"
              aria-hidden={!error}
            >
              <ElvisIcon
                color="var(--e-light-theme-red)"
                fontSize={16}
                iconName="removeCircle"
              />
            </i>
          </span>
          <span id="error-message-example">{error}</span>
        </span>
      </div>
    )
  }
)

Input.displayName = 'Input'
