import classnames from 'classnames'
import React, { CSSProperties, FC, ReactNode, useState } from 'react'

import { MediaTypes, useMedia } from '../../hooks/useMedia/useMedia'
import { ElvisIcon } from '../ElvisIcon'
import { TextBody } from '../Typography'
import * as css from './FaqBlock.module.scss'

export interface FaqBlockProps {
  children: ReactNode
  title: string
  faqFooterProps?: Record<string, string>
  className?: string
  style?: CSSProperties
  articleStyle?: boolean
  firstElement?: boolean
}

export const FaqBlock: FC<FaqBlockProps> = ({
  children,
  title,
  className,
  style,
  firstElement,
}) => {
  const [active, setActive] = useState(false)
  const [hover, setHover] = useState(false)

  const desktop = useMedia(MediaTypes.DESKTOP)

  return (
    <div
      style={style}
      className={classnames(css.faqContainer, className, {
        [css.faqContainerFirstElement]: firstElement,
      })}
    >
      <button
        aria-expanded={active}
        data-testid="faq-header"
        onClick={() => setActive(!active)}
        className={css.faqHeader}
        onMouseEnter={() => desktop && setHover(true)}
        onMouseLeave={() => desktop && setHover(false)}
      >
        <TextBody className={css.textBody}>{title}</TextBody>
        <div
          className={classnames(css.faqIconContainer, {
            [css.rotated]: active,
          })}
        >
          <ElvisIcon
            fontSize={32}
            iconName={
              hover ? 'collapseCircleFilledColor' : 'collapseCircleColor'
            }
          />
        </div>
      </button>
      <div
        role="region"
        className={classnames(css.faqContentContainer, {
          [css.faqContentContainerHide]: !active,
        })}
        data-testid="faq-content"
      >
        <div className={css.faqContent}>{children}</div>
      </div>
    </div>
  )
}
