// extracted by mini-css-extract-plugin
export var imageWrapper = "LinkImageBlock-module--imageWrapper--91a2c";
export var line = "LinkImageBlock-module--line--5ea7f";
export var linkimageblock = "LinkImageBlock-module--linkimageblock--ffc00";
export var linkimageblockCentered = "LinkImageBlock-module--linkimageblockCentered--cb615";
export var linkimageblockSidebar = "LinkImageBlock-module--linkimageblockSidebar--81ffa";
export var textBody = "LinkImageBlock-module--text-body--e2c56";
export var textWrapper = "LinkImageBlock-module--textWrapper--22928";
export var textWrapperGradient = "LinkImageBlock-module--textWrapperGradient--7fa6a";
export var titleMedium = "LinkImageBlock-module--title-medium--e01f4";
export var titleSmall = "LinkImageBlock-module--title-small--3e7c4";