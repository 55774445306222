import dayjs from 'dayjs'
import 'dayjs/locale/nb'

import React, { FC } from 'react'

import {
  DatepickerRange as ElvisDatepickerRange,
  DatepickerRangeProps as ElvisDatepickerRangeProps,
} from '@elvia/elvis-datepicker-range/react'

export interface DateRange {
  fromDate: string
  toDate: string
}

interface DatepickerRangeProps
  extends Omit<
    ElvisDatepickerRangeProps,
    'value' | 'valueOnChangeISOString' | 'valueOnChange'
  > {
  value?: null | DateRange
  onChangeValues: (fromDate: string | null, toDate: string | null) => void
}

export const DatepickerRange: FC<DatepickerRangeProps> = ({
  value,
  onChangeValues,
  size = 'small',
  hasSelectDateOnOpen = false,
  hasAutoOpenEndDatepicker = true,
  ...rest
}) => {
  const { fromDate, toDate } = value ?? {}
  const newValue = {
    start: fromDate ? dayjs(fromDate).toDate() : null,
    end: toDate ? dayjs(toDate).toDate() : null,
  }
  return (
    <ElvisDatepickerRange
      hasAutoOpenEndDatepicker={hasAutoOpenEndDatepicker}
      hasSelectDateOnOpen={hasSelectDateOnOpen}
      value={newValue ? newValue : undefined}
      valueOnChange={dateRange => {
        const fromString = dateRange?.start
          ? dayjs(dateRange.start).format('YYYY-MM-DD')
          : null
        const toString = dateRange?.end
          ? dayjs(dateRange.end).format('YYYY-MM-DD')
          : null
        onChangeValues(fromString, toString)
      }}
      size={size}
      {...rest}
    />
  )
}
