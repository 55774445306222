import { useQuery, UseQueryResult } from '@elvia/kundeportal-api'

export interface JobylonEntry {
  id: number
  company: {
    id: number
    slug: string
    name: string
    descr: string
    industry: string
  }
  departments: { department: { id: number; descr: string; name: string } }[]
  employment_type: string
  from_date: string
  descr: string
  locations: [
    {
      location: {
        area_1: string
        area_1_short: string
        city: string
        city_short: string
        country: string
        country_short: string
        place_id: string
        url: string
        text: string
      }
    }
  ]
  urls: {
    ad: string
    apply: string
  }
  slug: string
  title: string
  to_date: string
  dt_created: string
  dt_modified: string
  status_changed: string
  layers_3: [
    {
      layer: {
        id: number
        text: string
        identifier: string
      }
    }
  ]
}

export const useJobylonApi: (
  url: string
) => UseQueryResult<JobylonEntry[], Error> = (url: string) => {
  return useQuery<JobylonEntry[]>({
    queryKey: ['jobylon', url],
    queryFn: () => {
      return fetch(url)
        .then(res => res.json())
        .catch(error => {
          throw new Error(error)
        })
    },
  })
}
