import React, { FC, useCallback, useEffect, useState } from 'react'

import {
  Tabs as ElvisTabs,
  TabsProps as ElvisTabsProps,
} from '@elvia/elvis-tabs/react'

export interface TabsProps extends ElvisTabsProps {
  urls?: string[]
}

export const Tabs: FC<TabsProps> = ({
  className,
  hasManualActivation = false,
  ariaLabel = 'tabsliste over faner',
  items,
  urls,
  valueOnChange,
  value = 0,
}) => {
  const [tabValue, setTabValue] = useState(value)
  const [focusedElement, setFocusedElement] = useState<HTMLElement | ''>('')

  useEffect(() => {
    setTabValue(value)
  }, [value])

  useEffect(() => {
    if (!urls?.length) return

    const indexOfTab = urls.indexOf(location.pathname)
    if (indexOfTab > -1) {
      setTabValue(indexOfTab)
      focusedElement && focusedElement.focus()
    }
  }, [urls, focusedElement])

  const handleValueOnChange = useCallback(
    (event: number) => {
      valueOnChange && valueOnChange(event)
      const focusedElement = document.activeElement as HTMLElement
      setFocusedElement(focusedElement)
    },
    [valueOnChange]
  )

  return (
    <ElvisTabs
      className={className}
      value={tabValue}
      hasManualActivation={hasManualActivation}
      items={items}
      ariaLabel={ariaLabel}
      valueOnChange={handleValueOnChange}
    />
  )
}
