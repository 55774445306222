// extracted by mini-css-extract-plugin
export var iconContainer = "RelatedContent-module--iconContainer--1018d";
export var imageContainer = "RelatedContent-module--imageContainer--2f6ee";
export var linkContainer = "RelatedContent-module--linkContainer--00487";
export var preamble = "RelatedContent-module--preamble--0166e";
export var preambleFour = "RelatedContent-module--preamble-four--2a059";
export var relatedContent = "RelatedContent-module--relatedContent--664e4";
export var relatedContentCenter = "RelatedContent-module--relatedContentCenter--28df7";
export var relatedContentMulti = "RelatedContent-module--relatedContentMulti--e37c1";
export var relatedContentSingle = "RelatedContent-module--relatedContentSingle--e960b";
export var textContainer = "RelatedContent-module--textContainer--6ef3a";
export var title = "RelatedContent-module--title--bb8ca";