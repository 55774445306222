import React, { FC } from 'react'

import {
  Popover as ElvisPopover,
  PopoverProps,
} from '@elvia/elvis-popover/react'

export const Popover: FC<PopoverProps> = ({ ...rest }) => {
  return <ElvisPopover {...rest} />
}
