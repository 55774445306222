import React, { FC, useState } from 'react'

import { Popover } from '@elvia/ui/src/components/Popover/Popover'
import * as css from './richTextRender.module.scss'
import classNames from 'classnames'

interface Props {
  title?: string
  text?: string
}

const Abbrevation: FC<Props> = ({ text, title }) => {
  const [isShowing, setIsShow] = useState(false)
  return (
    <div className={css.abbrevation}>
      <Popover
        content={text ?? '[mangler beskrivelse]'}
        heading={title ?? '[mangler forkortelse]'}
        onOpen={() => setIsShow(true)}
        onClose={() => setIsShow(false)}
        trigger={
          <button
            className={classNames(
              'e-text-abbreviation',
              'e-text-body',
              isShowing && 'e-text-abbreviation---active',
              css.abbrevationButton
            )}
          >
            {title ?? '[mangler forkortelse]'}
          </button>
        }
      />
    </div>
  )
}

export default Abbrevation
