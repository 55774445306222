export const openCookiePreferences = (): void => {
  document
    .getElementById('onetrust-pc-sdk')
    ?.setAttribute(
      'style',
      'display: block!important; height: calc(100% - 180px)!important'
    )
  document
    .getElementsByClassName('onetrust-pc-dark-filter')[0]
    ?.setAttribute('style', 'display: block!important')
}
