import React from 'react'
import { FuseResultMatch } from 'fuse.js'

/**
 * Consists of a number pair, where the first is the match start index,
 * and the second the match end index.
 */
type MatchPosition = [number, number]

/**
 * Takes a list of matches and a highlighter, and returns a text with highlighted parts.
 */
export const formatText = (
  match: FuseResultMatch,
  prevMatchIndex: number,
  highlighter: (word: string, key: number) => React.ReactNode
): JSX.Element => {
  const matchIndices = [...match.indices].sort((a, b) => a[0] - b[0])
  let lastMatchIndex = prevMatchIndex
  if (match.value === undefined) return <></>
  const matchValue = match.value

  const highlighedText = matchIndices.map((matchIndex: MatchPosition) => {
    const res = []
    if (lastMatchIndex < matchIndex[0]) {
      res.push(matchValue.substring(lastMatchIndex, matchIndex[0]))
    }
    if (matchIndex[0] >= lastMatchIndex) {
      res.push(
        highlighter(
          matchValue.substring(matchIndex[0], matchIndex[1] + 1),
          lastMatchIndex
        )
      )
      lastMatchIndex = matchIndex[1] + 1
    }
    return res
  })

  if (lastMatchIndex < match.value.length) {
    highlighedText.push([matchValue.substring(lastMatchIndex)])
  }
  return <>{highlighedText.flat() as JSX.Element[]}</>
}
