import classnames from 'classnames'
import React, { FC } from 'react'

import { IconName } from '@elvia/ui/src/components/ElvisIcon'
import { Link, LinkType } from '@elvia/ui/src/components/Link'
import { useLocalization } from '@elvia/localization/src/useLocalization'

import * as css from './LinkCollectionSection.module.scss'

interface CollectionLink {
  title: { nb: string; [key: string]: string }
  icon: string
  url: { nb: string; [key: string]: string }
  newTab?: boolean
  asset?: { fields: { file: { nb: { url: string } } } }
}

interface LinkCollectionSectionProps {
  collection: CollectionLink[]
  type?: LinkType
  className?: string
}

export const LinkCollectionSection: FC<LinkCollectionSectionProps> = ({
  collection,
  type = LinkType.Jumbo,
  className,
}) => {
  const { getLocalizedValue } = useLocalization()
  return (
    <div className={classnames(className, css.wrapper)}>
      <div className={css.collection}>
        {collection?.map((link: CollectionLink, index: number) => {
          if (!link.asset && !getLocalizedValue(link.url, true)) return null
          return (
            <Link
              key={index}
              type={type}
              icon={link.icon as IconName}
              href={
                link.asset
                  ? link.asset.fields.file.nb.url
                  : getLocalizedValue(link.url)
              }
              target={link.newTab ? '_blank' : '_self'}
              rel={link.newTab ? 'noopener noreferrer' : ''}
            >
              {getLocalizedValue(link.title)}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
