import React, {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useCallback,
} from 'react'

import { getLocalDefaultNb, getLocale } from './index'

interface LocalizationContextProps {
  locale: string
  setLocale: (locale: string) => void
  getLocalizedValue<T>(
    data?: { nb: T; [key: string]: T },
    noFallback?: boolean
  ): T
}

const LocalizationContext = createContext<LocalizationContextProps>(
  {} as LocalizationContextProps
)

interface LocalizationProviderProps {
  initialLocale: string
}

export const LocalizationProvider: FC<
  PropsWithChildren<LocalizationProviderProps>
> = ({ initialLocale, children }) => {
  const [locale, setLocale] = useState<string>(initialLocale)

  const getLocalizedValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: { nb: any; [key: string]: any }, noFallback?: boolean) => {
      if (noFallback) {
        return getLocale(locale, data)
      }
      return getLocalDefaultNb(locale, data)
    },
    [locale]
  )

  return (
    <LocalizationContext.Provider
      value={{ locale, setLocale, getLocalizedValue }}
    >
      {children}
    </LocalizationContext.Provider>
  )
}

export const useLocalization = (): LocalizationContextProps => {
  const context = useContext(LocalizationContext)

  if (context === undefined) {
    throw new Error(
      'useLocalization must be used within a LocalizationProvider'
    )
  }

  return context
}
