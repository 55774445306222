import React, { FC } from 'react'

import { useSiteImprove } from '@elvia/analytics'
import { useTerms } from '@elvia/terms'
import { useLocalization } from '@elvia/localization/src/useLocalization'
import { EmbeddedVideo } from '@elvia/ui/src/components/EmbeddedVideo'
import { useCookieConsentCheck, TypeOfCookie } from '@elvia/cookies'

import { CookiesNotConsentedBox } from './CookiesNotConsentedBox'

export interface EmbeddedVideoWrapperProps {
  title: { nb: string; [key: string]: string }
  src: string
  description: { nb: string; [key: string]: string }
  fullWidth?: boolean
}

export const EmbeddedVideoWrapper: FC<EmbeddedVideoWrapperProps> = ({
  title,
  src,
  description,
  fullWidth,
}) => {
  const { terms } = useTerms()

  const isYouTubeVideo = src.includes('youtube')
  const { playVideoEvent } = useSiteImprove()
  const { isCookieConsented } = useCookieConsentCheck(TypeOfCookie.Functional)
  const { getLocalizedValue } = useLocalization()

  return (
    <div>
      {!isYouTubeVideo || isCookieConsented ? (
        <EmbeddedVideo
          title={getLocalizedValue(title)}
          src={src}
          description={getLocalizedValue(description)}
          fullWidth={fullWidth}
          onPlay={() => playVideoEvent(getLocalizedValue(title))}
          isYouTubeVideo={isYouTubeVideo}
        />
      ) : (
        <CookiesNotConsentedBox
          header={terms.embeddedVideoYoutubeCookieConsentHeader}
          descriptionText={terms.embeddedVideoYoutubeCookieConsentDescription}
          buttonText={terms.embeddedVideoYoutubeCookieConsentButtonText}
        />
      )}
    </div>
  )
}
