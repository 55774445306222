import classnames from 'classnames'
import React, { CSSProperties, ReactElement, useState, useRef, FC } from 'react'

import { useClickOutside } from '../../hooks/useClickOutside/useClickOutside'
import * as css from './Header.module.scss'

export interface HeaderProps {
  main: ReactElement
  /**
   * Renders a space above the main header section. Will not render anything (not even an empty space) if false or null.
   */
  menu?: ReactElement | false
  closeButton?: ReactElement
  onClickOutside?: (() => void) | false
  className?: string
  style?: CSSProperties
}

export const Header: FC<HeaderProps> = ({
  main,
  menu,
  onClickOutside,
  className,
  style,
  closeButton,
}) => {
  const headerMenuRef = useRef<HTMLDivElement>(null)
  const [menuElement, setMenuElement] = useState<ReactElement | null>()
  useClickOutside(headerMenuRef, event => {
    const dataset = (event.target as HTMLElement)?.dataset
    // TODO: Map children, adding data-isheaderbutton="yes"
    const isHeaderButton = dataset['isheaderbutton'] === 'yes'
    if (onClickOutside && menu && !isHeaderButton) {
      onClickOutside()
    }
  })

  if (menu && menu !== menuElement) {
    setMenuElement(menu)
  } else {
    setTimeout(() => setMenuElement(null), 200)
  }

  return (
    <>
      <div
        className={classnames(css.menuBackgroundOverlay, {
          [css.menuBackgroundOverlayActive]: !!menu,
        })}
      />
      <div className={classnames(css.headerWrapper, className)} style={style}>
        <header
          data-testid="Header"
          className={classnames(css.header, {
            [css.headerActive]: !!menu,
          })}
        >
          {main}
        </header>
        <div
          className={classnames(css.headerMenuWrapper, {
            [css.headerMenuWrapperActive]: !!menu,
          })}
        >
          <div
            className={classnames(css.headerMenu, {
              [css.headerMenuActive]: !!menu,
            })}
            ref={headerMenuRef}
          >
            {menu && (
              <div className={css.headerMenuCloseButton}>{closeButton}</div>
            )}
            {menu && <div className={css.headerMenuContent}>{menuElement}</div>}
          </div>
        </div>
      </div>
    </>
  )
}

Header.displayName = 'Header'
