import React, {
  ComponentType,
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'
import { useLocalization } from '@elvia/localization/src/useLocalization'

import { DynamicTerms, RichTerms, Terms, TermsDictionary } from './Terms'

const defaultTerms: TermsDictionary = {} as TermsDictionary
const TermsContext = createContext(defaultTerms)

export interface InitialTermsProps {
  terms: {
    nb: Terms
    [key: string]: Terms
  }
  richTerms: {
    nb: RichTerms
    [key: string]: RichTerms
  }
  dynamicTerms: {
    nb: DynamicTerms
    [key: string]: DynamicTerms
  }
}

export const TermsProvider: ComponentType<
  PropsWithChildren<{
    initialTerms: InitialTermsProps
  }>
> = ({ initialTerms, children }) => {
  const { getLocalizedValue } = useLocalization()

  const { terms, richTerms, dynamicTerms } = useMemo(() => {
    return {
      terms: getLocalizedValue(initialTerms.terms),
      richTerms: getLocalizedValue(initialTerms.richTerms),
      dynamicTerms: getLocalizedValue(initialTerms.dynamicTerms),
    }
  }, [getLocalizedValue, initialTerms])

  return (
    <TermsContext.Provider value={{ terms, dynamicTerms, richTerms }}>
      {children}
    </TermsContext.Provider>
  )
}

export const useTerms = <
  Filter extends string = string
>(): TermsDictionary<Filter> => {
  const { terms, richTerms, dynamicTerms } = useContext(TermsContext)
  return { terms, richTerms, dynamicTerms }
}
