import React, { FC, ReactNode } from 'react'
import * as css from './DataList.module.scss'
import classNames from 'classnames'

export interface DataListItemProps {
  title: NonNullable<ReactNode>
  children: NonNullable<ReactNode>
}

export const DataListItem: FC<DataListItemProps> = ({ title, children }) => {
  return (
    <>
      <dt className={classNames(css.dataListItem, css.dataListItemTitle)}>
        {title}
      </dt>
      <dd className={classNames(css.dataListItem)}>{children}</dd>
    </>
  )
}
