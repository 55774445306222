import React, { FC } from 'react'

import * as elvisIcons from '@elvia/elvis-assets-icons/icons'

import { baseFontSize } from '../../theme'
import { IconType } from '@elvia/elvis-assets-icons/dist/icons/_iconType'
import classnames from 'classnames'
import * as css from './ElvisIcon.module.scss'

export type IconName = elvisIcons.IconName

interface IconProps {
  iconName: elvisIcons.IconName
  color?: string
  fontSize?: number
  style?: React.CSSProperties
  className?: string
}

const getIconInEm = (Icon: string) => {
  const wReg = /width="([^"]*)"/
  const hReg = /height="([^"]*)"/
  const width = wReg.exec(Icon)
  const height = hReg.exec(Icon)
  const w = width ? Number(width[1]) : 24
  const h = height ? Number(height[1]) : 24
  const wEm = `${w / baseFontSize}em`
  const hEm = `${h / baseFontSize}em`

  const NewIcon = Icon.replace(wReg, `width="${w / baseFontSize}em"`).replace(
    hReg,
    `height="${h / baseFontSize}em" display="block"`
  )

  return { Icon: NewIcon, emHeight: hEm, emWidth: wEm }
}

const fontSizeInRemFromPx = (fontSize: number) =>
  `${fontSize / baseFontSize / 1.5}rem`

export const ElvisIcon: FC<IconProps> = ({
  iconName,
  color = '',
  fontSize,
  style,
  className,
}) => {
  if (iconName == undefined) return null
  let namePlaceholder: string = iconName
  if (iconName.startsWith('Elvia')) {
    namePlaceholder = iconName.substring(5)
    namePlaceholder =
      namePlaceholder[0]?.toLowerCase() + namePlaceholder.substring(1)
  }
  const dashedColor = color.replace(/[A-Z]/g, m => '-' + m.toLowerCase())

  const Icon = (elvisIcons as Record<string, IconType>)[
    namePlaceholder
  ]?.getIcon(dashedColor)

  if (Icon == undefined) {
    return null
  }
  const EmIcon = getIconInEm(Icon)
  const fontSizeInRem = fontSize ? fontSizeInRemFromPx(fontSize) : 'inherit'

  return (
    <span
      style={{
        fontSize: fontSizeInRem,
        ...style,
      }}
      dangerouslySetInnerHTML={{ __html: EmIcon.Icon }}
      className={classnames(className, css.elvisIcon)}
    />
  )
}

ElvisIcon.displayName = 'ElvisIcon'
