import classnames from 'classnames'
import React, { ReactNode, forwardRef, HTMLAttributes } from 'react'

import { ElvisIcon, IconName } from '../ElvisIcon'
import { TextDescription } from '../Typography'
import * as css from './Link.module.scss'

export enum LinkType {
  External = 'External',
  Internal = 'Internal',
  Action = 'Action',
  Back = 'Back',
  NoDecoration = 'NoDecoration',
  Button = 'Button',
  TertiaryButton = 'TertiaryButton',
  Jumbo = 'Jumbo',
  Default = 'Default',
  Icon = 'Icon',
}
export interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  href: string
  children?: ReactNode
  icon?: IconName
  type?: LinkType
  size?: 'small' | 'large'
  iconColor?: string
  target?: '_blank' | '_self'
}

const checkDomain = (url: string) => {
  if (url.indexOf('//') === 0) {
    url = location.protocol + url
  }
  return url
    .toLowerCase()
    .replace(/([a-z])?:\/\//, '$1')
    .split('/')[0]
}

const isExternal = (url: string) => {
  if (typeof document === 'undefined' || typeof url === 'undefined') {
    return false
  }
  return (
    url.indexOf('//') > -1 && checkDomain(location.href) !== checkDomain(url)
  )
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const {
    children,
    type = LinkType.Default,
    icon,
    size,
    iconColor,
    className,
    ...rest
  } = props

  const commonProps = {
    ref,
    ...rest,
  }

  const linkSizeClass = {
    'e-link--sm': size === 'small',
    'e-link--md': !size,
    'e-link--lg': size === 'large',
  }

  const defaultClasses = ['e-link', linkSizeClass, className]

  switch (type) {
    case LinkType.Action:
      return (
        <a
          className={classnames(
            ...defaultClasses,
            'e-link--action',
            css.linkIcon
          )}
          {...commonProps}
        >
          <span className="e-link__title">{children}</span>
          <span className={classnames('e-link__icon', css.iconContainer)}>
            <ElvisIcon
              fontSize={18}
              iconName={'arrowCircleColor'}
              color={iconColor}
            />
            <ElvisIcon
              fontSize={18}
              iconName={'arrowCircleFilledColor'}
              color={iconColor}
            />
          </span>
        </a>
      )

    case LinkType.Back:
      return (
        <a
          className={classnames(
            ...defaultClasses,
            'e-link--back',
            css.linkIcon
          )}
          {...commonProps}
        >
          <span className={classnames('e-link__icon', css.iconContainer)}>
            <ElvisIcon
              fontSize={24}
              iconName={'arrowLeftCircleColor'}
              color={iconColor}
            />
            <ElvisIcon
              fontSize={24}
              iconName={'arrowLeftCircleFilledColor'}
              color={iconColor}
            />
          </span>
          <span className="e-link__title">{children}</span>
        </a>
      )
    // This and LinkType.External correspond to what Elvis calls inline link
    case LinkType.Internal:
      return (
        <a
          className={classnames(...defaultClasses, 'e-link--inline')}
          {...commonProps}
        >
          {props.target === '_blank' ? (
            <>
              <span className="e-link__title">{children}</span>
              <span className="e-link__icon">
                <ElvisIcon fontSize={16} iconName="arrowExternalBold" />
              </span>
            </>
          ) : (
            children
          )}
        </a>
      )
    // This and LinkType.External correspond to what Elvis calls inline link
    case LinkType.External:
      return (
        <a
          className={classnames(...defaultClasses, 'e-link--inline')}
          {...commonProps}
        >
          <span className="e-link__title">{children}</span>
          {isExternal(props.href) && props.target === '_blank' && (
            <span className="e-link__icon">
              <ElvisIcon fontSize={16} iconName="arrowExternalBold" />
            </span>
          )}
        </a>
      )
    case LinkType.Icon:
      return (
        <a
          className={classnames(
            ...defaultClasses,
            'e-link--external',
            css.content,
            css.noBorderBottom
          )}
          {...commonProps}
        >
          <div className={css.iconContainer}>
            <ElvisIcon iconName={icon || 'warningCircle'} />
          </div>
          <div className={css.textContainer}>
            <TextDescription className={css.textDescription}>
              {children}
            </TextDescription>
          </div>
        </a>
      )
    case LinkType.NoDecoration:
      return (
        <a
          className={classnames(className, 'e-link', css.noBorderBottom)}
          {...commonProps}
        >
          {children}
        </a>
      )
    case LinkType.Button:
      return (
        <a className={classnames('e-btn', className)} {...commonProps}>
          {children}
        </a>
      )
    case LinkType.TertiaryButton:
      return (
        <a
          className={classnames('e-btn', 'e-btn--tertiary', className)}
          {...commonProps}
        >
          {children}
        </a>
      )
    case LinkType.Jumbo:
      return (
        <a
          className={classnames(...defaultClasses, css.jumbo)}
          {...commonProps}
        >
          {icon && (
            <span className="e-link__icon">
              <ElvisIcon fontSize={38} iconName={icon} />
            </span>
          )}
          <span className="e-link__title">{children}</span>
          <span className="e-link__icon">
            <ElvisIcon fontSize={13} iconName="arrowRightBold" />
          </span>
        </a>
      )
    default:
      return (
        <a className={classnames(...defaultClasses)} {...commonProps}>
          {children}
        </a>
      )
  }
})

Link.displayName = 'Link'
