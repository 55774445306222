import classnames from 'classnames'
import React, { FC, ReactNode, CSSProperties } from 'react'

import { Popover } from '../Popover'

import { Button, ButtonType } from '../Button'
import { ElvisIcon } from '../ElvisIcon'
import { TextCaps, TypographyTag } from '../Typography'
import * as css from './DashboardBlock.module.scss'

export interface DashboardBlockProps {
  children: ReactNode
  className?: string
  style?: CSSProperties
  title?: string | ReactNode
  titleTag?: TypographyTag
  info?: ReactNode
  infoTitle?: string
  /**
   * Removes padding and background color so that children can control it
   */
  noBackgroundAndPadding?: boolean
  height?: string
  width?: string
  noHeader?: boolean
  onClickInfoIcon?: () => void
  dialogButtonText?: string
  triggerButtonId?: string
}

export const DashboardBlock: FC<DashboardBlockProps> = ({
  children,
  className,
  height = undefined,
  width,
  style,
  title = ' ', // must have whitespace to prevent collapse of header
  titleTag,
  info,
  infoTitle = '',
  noBackgroundAndPadding = false,
  noHeader = false,
  onClickInfoIcon,
  triggerButtonId,
}) => {
  const classes = classnames(css.dashboard, className)

  if (width) {
    Object.assign({}, style, { width: width })
  }

  return (
    <div data-testid="DashboardBlock" className={classes} style={style}>
      {!noHeader && (
        <div className={css.dashboardBlockHeader}>
          <TextCaps tag={titleTag}>{title}</TextCaps>
          {info && (
            <Popover
              verticalPosition="bottom"
              horizontalPosition="center"
              hasCloseButton
              heading={infoTitle}
              trigger={
                <Button
                  id={triggerButtonId}
                  asType={ButtonType.Icon}
                  aria-label={`Åpne ${title} informasjonsdialog`}
                  circled
                  onClick={() => onClickInfoIcon && onClickInfoIcon()}
                  icon={<ElvisIcon iconName={'informationCircle'} />}
                  hoverIcon={
                    <ElvisIcon iconName={'informationCircleFilledColor'} />
                  }
                />
              }
              content={
                <div className={css.dashboardBlockInfoContent}>{info}</div>
              }
            />
          )}
        </div>
      )}
      <div
        style={{ height: height }}
        className={classnames(css.dashboardContentContainer, {
          [css.dashboardBackgroundAndPadding]: !noBackgroundAndPadding,
        })}
      >
        {children}
      </div>
    </div>
  )
}
