import { Document } from '@contentful/rich-text-types'
import classnames from 'classnames'
import React, { FC } from 'react'

import { useTerms } from '@elvia/terms'
import { useLocalization } from '@elvia/localization/src/useLocalization'
import { FaqBlock } from '@elvia/ui/src/components/FaqBlock'

import { RenderRichText } from '../richTextRender'
import * as css from './FaqWrapper.module.scss'

export interface FaqBlock {
  id: string
  typeName: string
  title: { nb: string; [key: string]: string }
  body: { nb: Body; [key: string]: Body }
}

interface FaqWrapperProps {
  faqBlocks: FaqBlock[]
  titleElements?: JSX.Element
  footerElements?: JSX.Element
  title?: { json: Document }
  className?: string
}

export const FaqWrapper: FC<FaqWrapperProps> = ({
  title,
  faqBlocks,
  className,
}) => {
  const { terms, richTerms } = useTerms()
  const { getLocalizedValue } = useLocalization()

  if (faqBlocks.every(faqBlock => !getLocalizedValue(faqBlock.title, true)))
    return null

  return (
    <div className={classnames(className, css.faqBlocks)}>
      <div className={css.faqBlocksHeader}>
        {title ? RenderRichText(title.json) : richTerms.faqBlockHeader()}
      </div>
      <div className={css.faqBlocks}>
        {faqBlocks?.map((faqBlock, index) => {
          if (
            !getLocalizedValue(faqBlock.title, true) ||
            !getLocalizedValue(faqBlock.body, true)
          )
            return null
          return (
            <FaqBlock
              firstElement={index === 0}
              key={getLocalizedValue(faqBlock.title)}
              title={getLocalizedValue(faqBlock.title)}
              faqFooterProps={{
                giveFeedbackText: terms.faqBlockFeedbackQuestion,
                feedbackDoneText: terms.faqBlockFeedbackDone,
                textareaLabel: terms.faqBlockFeedbackTextareaDescription,
                cancelFeedbackText: terms.minSideButtonCancel,
                sendFeedbackText: terms.faqBlockFeedbackSend,
                textareaPlaceholder: terms.faqBlockFeedbackTextareaPlaceholder,
                yes: terms.yes,
                no: terms.no,
              }}
            >
              {RenderRichText(
                getLocalizedValue(faqBlock.body) as unknown as Document
              )}
            </FaqBlock>
          )
        })}
      </div>
    </div>
  )
}
