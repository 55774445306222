import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { EmptyInfoOnGreyColor, EmptyInfoOnWhiteColor } from '@elvia/icons'

import { MediaTypes, useMediaOnly } from '../../hooks/useMedia/useMedia'
import { Typography, TypographyType } from '../Typography'
import * as css from './ContentLoaderWithStates.module.scss'

export interface EmptyStateProps {
  addWhiteBackground?: boolean
  centered?: boolean
  className?: string
  customIcon?: ReactNode
  emptyTerm?: string
  emptyTitle?: string
  emptyRichTerm?: ReactNode
  invertedIcon?: boolean
  noPadding?: boolean
}

export const EmptyState: FC<EmptyStateProps> = ({
  addWhiteBackground,
  centered,
  className,
  customIcon,
  emptyTerm,
  emptyTitle,
  emptyRichTerm,
  invertedIcon,
  noPadding,
}) => {
  const classes = classnames(className, css.messageContainer, {
    [css.addWhiteBackground]: addWhiteBackground,
    [css.centered]: centered,
    [css.noPadding]: noPadding,
  })
  const isMobile = useMediaOnly(MediaTypes.MOBILE)

  const typographyType = isMobile
    ? TypographyType.TEXT_SMALL
    : TypographyType.TEXT_MEDIUM

  const emptyIcon = invertedIcon ? (
    <EmptyInfoOnGreyColor />
  ) : (
    <EmptyInfoOnWhiteColor />
  )

  return (
    <div className={classes}>
      {customIcon ? customIcon : emptyIcon}
      <div className={css.messageText}>
        {emptyRichTerm ? (
          emptyRichTerm
        ) : (
          <>
            <Typography type={typographyType}>
              <b>{emptyTitle}</b>
            </Typography>
            <Typography type={typographyType}>{emptyTerm}</Typography>
          </>
        )}
      </div>
    </div>
  )
}
