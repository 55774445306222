import React, { FC } from 'react'

import { useTerms } from '@elvia/terms'
import { Table as TableComponent } from '@elvia/ui/src/components/Table'
import { TitleMedium } from '@elvia/ui/src/components/Typography'

import RestructureTableData from '@elvia/ui/src/utils/RestructureTableData'

interface TableCell {
  value: string | JSX.Element
}

interface TableProps {
  data?: TableCell[][]
  className?: string
  isLimitedWidth?: boolean
}

export const Table: FC<TableProps> = ({ data, className, isLimitedWidth }) => {
  const { terms } = useTerms()

  if (!data)
    return (
      <TableComponent className={className} isLimitedWidth={isLimitedWidth}>
        <tbody>
          <td>
            <TitleMedium>{terms.tableMissingData}</TitleMedium>
          </td>
        </tbody>
      </TableComponent>
    )

  const [headers, ...rows] = data

  return (
    <TableComponent className={className} isLimitedWidth={isLimitedWidth}>
      <RestructureTableData>
        <thead key="1">
          <tr>
            {headers?.map(({ value }: TableCell, index: number) => (
              <th key={index}>
                {typeof value === 'string' ? (
                  <div dangerouslySetInnerHTML={{ __html: value }} />
                ) : (
                  value
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody key="2">
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map(({ value }: TableCell, cellIndex: number) => (
                <td key={cellIndex}>
                  {typeof value === 'string' ? (
                    <div dangerouslySetInnerHTML={{ __html: value }} />
                  ) : (
                    value
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </RestructureTableData>
    </TableComponent>
  )
}
