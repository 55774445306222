import classnames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { TextLarge, TextMedium } from '../Typography'
import { WentWrongOnGreyColor, WentWrongOnWhiteColor } from '@elvia/icons'

import * as css from './ContentLoaderWithStates.module.scss'

export interface ErrorProps {
  errorTitle: ReactNode | string
  errorTerm: ReactNode | string
  addWhiteBackground?: boolean
  centered?: boolean
  invertedIcon?: boolean
}

export const ErrorState: FC<ErrorProps> = ({
  errorTitle,
  errorTerm,
  invertedIcon,
  addWhiteBackground,
  centered,
}) => {
  const classes = classnames(css.messageContainer, {
    [css.addWhiteBackground]: addWhiteBackground,
    [css.centered]: centered,
  })

  return (
    <div className={classes}>
      {addWhiteBackground || invertedIcon ? (
        <WentWrongOnWhiteColor />
      ) : (
        <WentWrongOnGreyColor />
      )}
      <div className={css.messageText}>
        <TextLarge>{errorTitle}</TextLarge>
        <TextMedium>{errorTerm}</TextMedium>
      </div>
    </div>
  )
}
