import classnames from 'classnames'
import { pipe, prop, reverse, sortBy } from 'ramda'
import React, { ReactNode, useState } from 'react'

import { ElvisIcon } from '../ElvisIcon'
import { MediaTypes, useMedia } from '../../hooks/useMedia'

import * as css from './SortableTableContent.module.scss'

export type SortableTableColumns<T> = {
  name: ReactNode
  key: keyof T | ''
  dataAnalytics?: string
}

type SortableTableContentProps<T> = {
  data: T[]
  renderRow: (contract: T, index: number) => ReactNode
  columns: SortableTableColumns<T>[]
  restructurable?: boolean
}

export const SortableTableContent = <T,>({
  data,
  renderRow,
  columns,
}: SortableTableContentProps<T>): JSX.Element => {
  const isTabletOrLarger = useMedia(MediaTypes.TABLET)
  const [activeKey, setActiveKey] = useState<keyof T | ''>('')
  const [ascending, setAscending] = useState<boolean>(true)

  const setSorting = (key: keyof T | '') => {
    if (key === activeKey && !ascending) {
      setActiveKey('')
      setAscending(true)
    } else if (key === activeKey) {
      setAscending(false)
    } else {
      setActiveKey(key)
      setAscending(true)
    }
  }

  const activeColumn = (key: keyof T | '') => key === activeKey

  const sort: (data: string) => (data: T[]) => T[] = pipe(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    prop as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sortBy as any
  ) as (data: string) => (data: T[]) => T[]
  const sortedAscending: (data: T[]) => T[] = sort(activeKey.toString())
  const rows = ascending
    ? sortedAscending(data)
    : reverse(sortedAscending(data))

  return (
    <>
      <thead>
        <tr>
          {columns.map(({ name, key, dataAnalytics }, index) => (
            <th
              key={index}
              onClick={() => key && setSorting(key)}
              data-analytics={dataAnalytics}
            >
              <div className={css.head}>
                <div
                  className={classnames(css.hide, {
                    [css.show]: activeColumn(key),
                  })}
                >
                  {name}
                  {key && isTabletOrLarger && (
                    <ElvisIcon
                      iconName={ascending ? 'arrowDownBold' : 'arrowUpBold'}
                    />
                  )}
                </div>
              </div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{rows.map(renderRow)}</tbody>
    </>
  )
}

export default SortableTableContent
