export const getLocalDefaultNb = <T>(
  locale: string,
  data?: { nb: T; [key: string]: T }
): T | undefined => {
  if (!data) {
    return undefined
  }
  if (!data[locale]) {
    return data.nb
  }
  return data[locale]
}

export const getLocale = <T>(
  locale: string,
  data?: { nb: T; [key: string]: T }
): T | undefined => {
  return data?.[locale]
}
